import { Action, ActionCreatorsMapObject, Reducer } from 'redux';
import { LocationState } from 'history';
import { toast } from 'react-toastify';
import { SagaMap } from './redux/store';
import { NavLinkItem } from './layout/NavDropdown';
import { texts } from './texts';

const routerTypes = {
  PUSH: 'ROUTER/PUSH',
  BACK: 'ROUTER/BACK',
};

export interface RouterActionCreators extends ActionCreatorsMapObject {
  routerPush: (location: string, state?: LocationState) => Action;
  goBack: () => Action;
}

export const routerActionCreators: RouterActionCreators = {
  routerPush: (location, state?) => ({type: routerTypes.PUSH, location, state}),
  goBack: () => ({type: routerTypes.BACK}),
};

const modalsTypes = {
  MESSAGE_BOX: 'MODALS/MESSAGE_BOX',
  CLEAR_ALL: 'MODALS/CLEAR_ALL',
};

export interface ModalsActionCreators extends ActionCreatorsMapObject {
  messageBox: (msg: string) => Action;
  clearAll: () => Action;
}

export const modalsActionCreators: ModalsActionCreators = {
  messageBox: (msg) => ({type: modalsTypes.MESSAGE_BOX, msg}),
  clearAll: () => ({type: modalsTypes.CLEAR_ALL}),
};

export interface ModalsState {
  message: string | undefined;
}

const initialState: ModalsState = {
  message: undefined,
};

export const modalsReducer: Reducer<ModalsState> = (state = initialState, action) => {
  switch (action.type) {
    case modalsTypes.CLEAR_ALL: {
      return {
        ...initialState,
      };
    }
    case modalsTypes.MESSAGE_BOX: {
      return {
        ...state,
        message: action.msg,
      };
    }
    default: {
      return state;
    }
  }
};

export const routerSagas: SagaMap = {
  [`router/push`]: {
    actionType: routerTypes.PUSH,
    * saga({location, state}, {history}) {
      history.push(location, state);
    },
  },
  [`router/back`]: {
    actionType: routerTypes.BACK,
    * saga({}, {history}) {
      history.goBack();
    },
  },
};

export interface ToastActionCreators extends ActionCreatorsMapObject {
  error: (message: string) => Action;
}

const toastActions = {
  SHOW_TOAST: 'TOAST/SHOW_TOAST',
};

export const toastActionCreators: ToastActionCreators = {
  error: (message) => ({type: toastActions.SHOW_TOAST, message, toastType: 'error'}),
};

export const toastSagas: SagaMap = {
  [`toast/show-toast`]: {
    actionType: toastActions.SHOW_TOAST,
    * saga({message, toastType}) {

      switch (toastType) {
        case 'error': {
          toast.error(message, {position: 'top-right'});
          break;
        }
        case 'success': {
          toast.success(message, {position: 'top-right'});
          break;
        }
        default: {
          throw new Error('Unknown toastType.');
        }
      }

      return 0;
    },
  },
};

declare module './redux/store' {

  interface ReduxState {
    modals: ModalsState;
  }

  interface AllActionCreators {
    toast: ToastActionCreators;
    router: RouterActionCreators;
    modals: ModalsActionCreators;
  }
}
/* Описание на главното меню */
export const menuItems: NavLinkItem[] = [

  {
    role: 'doctor',
    name: 'Образна диагностика',
    feature: 'DicomPage',
    link: '/dicom/studies/catalog',
    color: 'bg-secondary',
    icon: 'fa-camera-retro',
  },
  {
    role: 'patient',
    name: 'Образна диагностика',
    feature: 'DicomPatientPage',
    link: '/dicom/patients/imaging-studies',
    icon: 'fa-camera-retro',
    color: 'bg-secondary',
  },
  {
    role: 'patient',
    name: 'Онлайн Консултация',
    feature: 'OnlineConsultationPage',
    link: '/patient/online-consultations',
    color: 'bg-secondary',
    icon: 'fa-calendar-check',
  },
  {
    role: 'patient',
    feature: 'ResultsPage',
    name: texts.MENU_ITEMS_LABTESTS,
    link: '/patient/results',
    icon: 'fa-flask',
    color: 'bg-secondary',
  },
  {
    role: 'patient',
    name: 'Документи / Documents',
    feature: 'PatientSignedDocuments',
    link: '/patient/signed-documents/catalog',
    color: 'bg-success',
    icon: 'fa-file',
  },
  {
    role: 'patient',
    feature: 'PrescriptionPage',
    name: texts.MENU_ITEMS_PRESCRIPTIONS,
    link: '/patient/prescriptions',
    icon: 'fa-pills',
    color: 'bg-secondary',
  },
  {
    role: 'patient',
    feature: 'VirtualPOSPage',
    name: texts.MENU_ITEMS_OBLIGATIONS,
    link: '/patient/obligations',
    icon: 'fa-credit-card',
    color: 'bg-success',
  },
  {
    role: 'patient',
    feature: 'BillingPage',
    name: texts.MENU_ITEMS_BILLING,
    link: '/patient/billing',
    icon: 'fa-money',
    color: 'bg-primary',
  },
  {
    role: 'patient',
    feature: 'VisitsPage',
    name: texts.MENU_ITEMS_VISITS,
    link: '/patient/visits',
    icon: 'fa-user-md',
    color: 'bg-info',
  },
  {
    role: 'patient',
    feature: 'AppointmentDelayPage',
    name: texts.MENU_ITEMS_APPO_DELAY,
    link: '/patient/appointment-delay',
    icon: 'fa-clock-o',
    color: 'bg-info',
  },
  {
    role: 'patient',
    feature: 'ChangePasswordPage',
    name: texts.MENU_ITEMS_PASS_CHANGE,
    link: '/patient/change-username-and-password',
    icon: 'fa-user',
    color: 'bg-warning',
  },
  {
    role: 'patient',
    feature: 'InvalidateAccountPage',
    name: texts.MENU_ITEMS_ACC_DISABLE,
    link: '/patient/invalidate-account',
    icon: 'fa-user-times',
    color: 'bg-danger',
  },
  {
    role: 'doctor',
    feature: 'DoctorSchedulePage',
    name: 'График',
    link: '/doctor/schedule',
    color: 'bg-primary',
    icon: 'fa-calendar-alt',
  },
  {
    role: 'doctor',
    feature: 'DoctorResultsPage',
    name: 'Резултати на пациенти',
    link: '/doctor/sender-doctor-results',
    color: 'bg-info',
    icon: 'fa-vial',
  },
  {
    role: 'doctor',
    feature: 'MicrobiologyPage',
    name: 'Микробиология',
    link: '/doctor/microbiology-results',
    userPrivilege: 'Microbiology',
    color: 'bg-secondary',
    icon: 'fa-bacteria',
  },
  {
    role: 'doctor',
    feature: 'ExternalResultsPage',
    name: 'Прикачени резултати',
    link: '/doctor/attached-results',
    userPrivilege: 'ExternalResults',
    color: 'bg-secondary',
    icon: 'fa-file-medical-alt',
  },
  {
    role: 'doctor',
    feature: 'CytologyDirectResultPage',
    name: 'Въведени цитологични резултати',
    link: '/doctor/cytology-results',
    userPrivilege: 'EditHistology',
    color: 'bg-success',
    icon: 'fa-flask',
  },
  {
    role: 'doctor',
    name: 'Въвеждане на резултати по заявка',
    link: '/doctor/lab-results',
    userPrivilege: 'EditHistology',
    feature: 'CytologyResultsPage',
    color: 'bg-success',
    icon: 'fa-clock-o',
  },
  {
    role: 'doctor',
    name: 'Поръчки',
    link: '/doctor/jrequests',
    userPrivilege: 'OnLineJRequests',
    color: 'bg-primary',
    icon: 'fa-list-alt',
  },
  {
    role: 'doctor',
    name: 'Подписване на документи',
    link: '/doctor/document-signing',
    feature: 'DocumentSigningPage',
    color: 'bg-primary',
    icon: 'fa fa-spinner',
    userPrivilege: 'DigitalSigning',
  },
  {
    role: 'doctor',
    name: 'Промяна на парола',
    link: '/doctor/change-password',
    color: 'bg-warning',
    icon: 'fa-key',
  },
  {
    role: 'doctor',
    feature: 'NotificationsPage',
    userPrivilege: 'MobileNotification',
    name: 'Нотификации',
    link: '/doctor/send-notifications',
    color: 'bg-primary',
    icon: 'fa-flask',
  },
  {
    role: 'doctor',
    name: 'Оперативен график',
    feature: 'OperationPlanningPage',
    link: '/doctor/operation-planing',
    color: 'bg-primary',
    icon: 'fa-file-medical-alt',
  },
  {
    role: 'patient',
    name: texts.MENU_ITEMS_TERMS,
    link: '/public/tos',
    icon: 'fa-list',
    color: 'bg-primary',
  },
  {
    role: 'patient',
    feature: 'ViberSubscriptionPage',
    name: texts.MENU_ITEMS_VIBER,
    link: '/patient/viber-subscription',
    color: 'bg-primary',
    icon: 'fab fa-viber',
  },
  {
    role: 'doctor',
    feature: 'ViberSubscriptionPage',
    name: texts.MENU_ITEMS_VIBER,
    link: '/doctor/viber-subscription',
    color: 'bg-primary',
    icon: 'fab fa-viber',
  },
  {
    role: 'doctor',
    feature: 'MedicalAgent',
    name: texts.MENU_ITEMS_MEDICAL_AGENT,
    link: '/doctor/medical-agent',
    color: 'bg-primary',
    icon: 'fa-notes-medical',
    userPrivilege: 'MedicalAgent',
  },
  {
    role: 'patient',
    feature: 'RSACertificate',
    name: 'СЕРТИФИКАТ / МОЯТ ПОДПИС',
    link: '/patient/rsa/certificate',
    color: 'bg-primary',
    icon: 'fa-file',
  },
  {
    role: 'patient',
    feature: 'RSACertificate',
    name: 'СЕРТИФИКАТ / МОИТЕ ДОКУМЕНТИ',
    link: '/patient/rsa/documents',
    color: 'bg-warning',
    icon: 'fa-list',
  },
];
