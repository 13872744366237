/* tslint:disable:max-line-length */
import { Result } from './infrastructure/api-result';
import * as validations from './infrastructure/fields/validations';
// @ts-ignore
import { SelectItem } from './infrastructure/fields/select-item';
import { JCoreClient } from './infrastructure/jcore-client';

export interface AppointmentWorkSchedulerRequest {
  fromDate: Date;
  toDate: Date;
  cabinetID: number;
  doctorID: number;
  isPrivate: boolean;
}

export interface AppointmentCabinetsRequest {

}

export interface AppoCabinetsRequest {

}

export interface AppointmentDoctorsRequest {
  cabinetID: number;
}

export interface AppoDoctorsRequest {
  cabinetID: number;
}

export interface AppointmentDaysRequest {
  cabinetID: number;
  doctorID: number;
}

export interface AppoDaysRequest {
  cabinetID: number;
  doctorID: number;
}

export interface AppointmentHoursRequest {
  cabinetID: number;
  doctorID: number;
  date: Date;
}

export interface AppoHoursRequest {
  cabinetID: number;
  doctorID: number;
  date: Date;
}

export interface MakeAppointmentRequest {
  item: AppointmentDto;
}

export interface AppointmentDto {
  cabinetID: number;
  doctorID: number;
  dateTime: Date;
  patientName: string;
  patientMobile: string;
  patientIdentNumber: string;
  note: string;
  address: string;
  patientID?: number;
  patientEmail: string;
}

export interface AppoMakeRequest {
  appoID: number;
  cabinetID: number;
  doctorID: number;
  dateTime: Date;
  patientName: string;
  patientMobile: string;
  patientIdentNumber: string;
  note: string;
  patientID?: number;
  patientEmail: string;
  isNzok: boolean;
  isFinFund: boolean;
  insurerId: string;
  insurerStatus: string;
  insurerName: string;
  reasonId?: number;
  reasonName: string;
  paymentId?: number;
  paymentName: string;
}

export interface AppoDeleteRequest {
  appoID: number;
}

export interface AppoGetRequest {
  appoIDs: number[];
  cabinetID: number;
  doctorID: number;
  fromDate?: Date;
  toDate?: Date;
  isEmpty: boolean;
}

export interface EventsSendMessage {
  senderPollSessionID: number;
  pollSessionID: number;
  pollMessageTypeName: string;
  pollMessageContain: string;
}

export interface EventsSendMessageBroadcast {
  senderPollSessionID: number;
  pollSessionID: number;
  pollMessageTypeName: string;
  pollMessageContain: string;
}

export interface EventsLoginRequest {
  login: EventsLogin;
}

export interface EventsLogin {
  computerName: string;
  ipInfo: string;
  jversion: string;
  loginDt: Date;
  outloginDt: Date;
  sessionID: number;
  userid: number;
  userDisplayInfo: string;
}

export interface EventsLogoutRequest {
  sessionID: number;
}

export interface EventsOnlineUsersRequest {

}

export interface UserMessageRequest {
  message: string;
}

export interface FirstContactRequest {
  ipAddress: string;
  computerName: string;
  joystickVersion: string;
}

export interface ClientLoginRequest {
  serverTime: Date;
  computerName: string;
  joystickVersion: string;
  userName: string;
  userPassword: string;
  ipAddress: string;
  subscriptionId: number;
}

export interface InventoryEmailReportRequest {

}

export interface IdentTypesRequest {

}

export interface LocationCountriesRequest {

}

export interface LocationStatesCitiesRequest {

}

export interface LocationQuartersRequest {
  cityID: number;
}

export interface GetOperationCabinetsRequest {

}

export interface GetOperationTypesRequest {

}

export interface GetOperationsRequest {
  workDate?: Date;
  grOperationID?: number;
}

export interface SaveOperationRequest {
  operation: GrOperationDto;
}

export interface GrOperationDto {
  grOperationID: number;
  grCabinetID: number;
  operatorDoctorName: string;
  grStartDateTime: Date;
  grEndDateTime: Date;
  grOperationTypeID: number;
  grDescription: string;
  grOperationPatientIdentNumber: string;
  grOperationPatientName: string;
  grOperationPatientMobile: string;
}

export interface DeleteOperationRequest {
  grOperationID: number;
}

export interface SetCurrentUpdateStatusRequest {
  isUpdating: boolean;
}

export interface CurrentStatusRequest {

}

export interface CurrentVersionRequest {

}

export interface VersionedResourcesRequest {
  forceUpdate: boolean;
  aCodesVersion: number;
  userPrivilegesVersion: number;
}

export interface GetMicrobiologyMaterialsRequest {
  referenceDate: Date;
  currentACode: string;
}

export interface GetMicrobiologyMicrobesRequest {
  referenceDate: Date;
  currentACode: string;
}

export interface GetMicrobiologyAntibiogramesRequest {
  referenceDate: Date;
}

export interface AiResultReadingRequest {
  uniqueRecordPrefix: string;
}

export interface GenerateCertificateRequest {
  pin: string;
}

export interface GetCertificateRequest {

}

export interface GetRsaDocumentsRequest {

}

export interface SignRsaDocumentRequest {
  signDocID: number;
  pin: string;
}

export interface GetRsaDocumentPreviewRequest {
  signDocID: number;
}

export interface DeleteCertificateRequest {

}

export interface SignPdfRequest {
  pdfBase64: string;
  certificatePfxBase64: string;
  certificatePassword: string;
}

export interface CheckPdfSigningRequest {
  pdfFilePath: string;
  pdfFileBase64: string;
}

export interface DigitalSignPdfRequest {
  type: number;
  pdfToSignPath: string;
  signedPdfPath: string;
  privateKeyPath: string;
}

export interface PatientAppointmentDelayRequest {

}

export interface PatientBillingInformationRequest {

}

export interface PatientBillingInvoiceDownloadRequest {
  fDocID: number;
}

export interface SaveFirebasePersonalTokenRequest {
  personalToken: string;
}

export interface PatientNotificationsRequest {

}

export interface ConsultGetJRequestsRequest {

}

export interface ConsultGetCabinetsDoctorsRequest {

}

export interface ConsultGetCabinetAppointmentsRequest {
  doctorID: number;
}

export interface ConsultGetPriceListRequest {
  cabinetID: number;
}

export interface ConsultSaveJRequestRequest {
  cabinetID: number;
  doctorID: number;
  workAppMetaID?: number;
  appointmentFromDateTime?: Date;
  patientEmail: string;
  jRequestNote: string;
  serviceIDs: number[];
}

export interface ConsultDeleteJRequestRequest {
  jRequestID: number;
}

export interface ConsultSaveAttachedResultRequest {
  pdfFileContent: string;
  patientRecShortName: string;
  jRequestID: number;
}

export interface ConsultDeleteAttachedResultRequest {
  patientRecID: number;
}

export interface PatientRegisterRequest {
  acceptToS: boolean;
  patient: PatientRegisterDto;
}

export interface PatientRegisterDto {
  identTypeID: number;
  patientIdentNumber: string;
  patientPersonalDocNumber: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientNameEN: string;
  patientBirthDay?: Date;
  patientSex: number;
  patientState: string;
  patientCity: string;
  patientAddress: string;
  patientMobile: string;
  patientEmail: string;
}

export interface ValidatePatientInformationRequest {
  item: NewPatientDto;
}

export interface NewPatientDto {
  patientID: number;
  patientEmail: string;
  patientMobile: string;
  identTypeID: number;
  patientIdentNumber: string;
  patientFirstName: string;
  patientSecondName?: string;
  patientLastName: string;
  patientBirthDay: Date;
  patientSex: number;
  patientNameEN?: string;
  patientPersonalDocNumber?: string;
}

export interface PatientPersonalDataRequest {

}

export interface GetPatientSignedDocumentRequest {
  signedDocument: PatientSignedDocumentDto;
}

export interface PatientSignedDocumentDto {
  signDocID: number;
  requestDatetime: Date;
  signedDatetime: Date;
  docName: string;
  signedFilePathID: number;
  signDocTypeName: string;
}

export interface GetPatientSignedDocumentsListRequest {

}

export interface PatientOwnInformationRequest {

}

export interface PatientPrescriptionsRequest {

}

export interface PatientPrescriptionPdfRequest {
  dcDyndocID: number;
}

export interface GetAdRequest {
  position: number;
  uniqueRecordPrefix: string;
}

export interface MarkAdAsDisplayedRequest {
  uniqueRecordPrefix: string;
  acodeID: number;
}

export interface MarkAdAsClickedRequest {
  uniqueRecordPrefix: string;
  acodeID: number;
}

export interface PatientRestorePasswordRequest {
  patient: PatientRestorePasswordDto;
}

export interface PatientRestorePasswordDto {
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientSex: number;
  patientBirthDay: Date;
  patientMobile: string;
  patientEmail: string;
}

export interface PatientLabResultsRequest {

}

export interface PatientResultRequest {
  uniqueRecordPrefix: string;
  documentLanguageType: number;
}

export interface PublicPatientResultRequest {
  qrID: string;
}

export interface DynamicPatientDossieGetRequest {
  departmentPationID: number;
}

export interface MergePdfsRequest {
  pdfsBase64: string[];
}

export interface GetPatientInformedConsentPdfRequest {
  header: string;
  body: string;
  footer: string;
  model: PatientInformedConsent;
}

export interface PatientInformedConsent {
  representativeType: number;
  representativeNames: string;
  representativeEGN: string;
  patientNames: string;
  patientContacts: string;
  issuedDate: Date;
  additionalInfo: string;
  currentDayServices: string;
  declaratorFullName: string;
  declaratorEGN: string;
  patientIdentNumber: string;
  thirdPersonInfo: string;
  declaratorMobileNumber: string;
  locationName: string;
  hospitalName: string;
  hospitalCity: string;
}

export interface GetViberQrRequest {

}

export interface GetViberPublicQrRequest {
  identification: string;
}

export interface PatientObligationsRequest {
  obligationKeys?: string[];
}

export interface PatientCartsRequest {
  orderIDs?: string[];
}

export interface PatientCreateCartRequest {
  obligations: PatientCreateCartItem[];
}

export interface PatientCreateCartItem {
  obligationKey: string;
  forPayActual: number;
}

export interface PatientCheckCartStatusRequest {
  cartID: number;
}

export interface PatientStornoCartRequest {
  cartID: number;
}

export interface ProcessCartPaymentRequest {
  cartID: number;
  responseData: string;
}

export interface PatientVisitsRequest {
  getPastYearVisits: boolean;
}

export interface CancellationAppointmentRequest {
  wAppointmentPatientID: number;
}

export interface JabchoSettingsRequest {
  loginID?: number;
}

export interface JabchoLoginRequest {
  oneSignalDeviceID: string;
}

export interface JabchoLogoutRequest {
  loginID: number;
}

export interface NotificationPatientsRequest {

}

export interface SendNotificationRequest {
  content: string;
  patientIDs?: number[];
}

export interface PatientQuestionsDoctorsRequest {

}

export interface PatientAskQuestionRequest {
  doctorID: number;
  content: string;
}

export interface PatientQuestionsListRequest {

}

export interface DisplayAppointmentRequest {

}

export interface AttachedResultsListRequest {
  fromDate: Date;
  patientIdentNumberBeginsWith?: string;
  patientFirstNameBeginsWith?: string;
  patientSecondNameBeginsWith?: string;
  patientLastNameBeginsWith?: string;
  docID?: string;
  toDate: Date;
}

export interface SaveAttachedResultRequest {
  item: AttachedResultDto;
}

export interface AttachedResultDto {
  patientRecDateTime: Date;
  pdfFileContent: string;
  patientRecShortName: string;
  docID: string;
  patientRecStatus: number;
  patientRecordID: number;
  patientID: number;
}

export interface GetAttachedResultRequest {
  patientRecID: number;
  includeFileContents: boolean;
}

export interface NewAttachedResultRequest {

}

export interface DeleteAttachedResultRequest {
  patientRecID: number;
}

export interface CytologyNomenclaturesRequest {

}

export interface CytologyResultListRequest {
  fromDate: Date;
  toDate: Date;
  patientIdentNumberBeginsWith?: string;
  docID?: number;
}

export interface GetCytologyResultRequest {
  patientRecordID: number;
}

export interface NewCytologyResultRequest {
  jRequestID: number;
}

export interface SaveCytologyResultRequest {
  item: CytologyResultDto;
}

export interface CytologyResultDto {
  patientRecID: number;
  patientID: number;
  cytoDoctorID: number;
  sendLabDateTime: Date;
  resultNum?: number;
  testMethod: number;
  groupPapanikolauPorcio?: number;
  groupPapanikolauCervikalenKanal?: number;
  groupPapanikolau?: number;
  normalCell: boolean;
  cellChangeWithList: number[];
  pilList: number[];
  cellChangeGlandAdenList: number[];
  cellChangeGland: boolean;
  otherTumor: boolean;
  hormoneValue?: number;
  groupASatisfactory: boolean;
  adekvatnostB: number[];
  adekvatnostV: number[];
  adekvatnostG: number[];
  conclusion: string;
  resultDate: Date;
  nextExamList?: number;
  recommendationsList: number[];
  isHasValue: boolean;
  jRequestID: number;
  clinicalInfoData: string;
}

export interface PrintCytologyResultRequest {
  patientRecordID: number;
}

export interface DeleteCytologyResultRequest {
  patientRecordID: number;
}

export interface DoctorCatalogRequest {
  doctorID?: number;
  doctorUIN?: string;
  doctorFirstName?: string;
  doctorSecondName?: string;
  doctorLastName?: string;
  doctorCategoryID?: number[];
}

export interface GetDoctorJRequestsRequest {
  fromDate: Date;
  toDate: Date;
  barcode?: string;
  patientFirstNameBeginsWith?: string;
  patientSecondNameBeginsWith?: string;
  patientLastNameBeginsWith?: string;
  patientRecordFilter?: number;
}

export interface GetJRequestRequest {
  jRequestID: number;
}

export interface SenderDoctorResultListRequest {
  fromDate: Date;
  toDate: Date;
  patientIdentNumber?: string;
  patientLastName?: string;
  patientFirstName?: string;
}

export interface SenderDoctorResultViewRequest {
  patientRecID?: number;
  patientID?: number;
}

export interface DoctorScheduleRequest {
  fromDate: Date;
  toDate: Date;
  doctorID?: number;
  cabinetID?: number;
  showAll: boolean;
}

export interface DoctorAppoMakeRequest {
  appoID: number;
  cabinetID: number;
  doctorID: number;
  dateTime: Date;
  patientName: string;
  patientMobile: string;
  patientIdentNumber: string;
  note: string;
  patientID?: number;
  isReserved: boolean;
}

export interface GetSignableDocumentsListRequest {

}

export interface GetNextDocumentToSignRequest {

}

export interface GetSignableDocumentRequest {
  documentID: number;
}

export interface SaveSignableDocumentRequest {
  documentID: number;
  images: PdfPageImage[];
  userPassword: string;
}

export interface PdfPageImage {
  index: number;
  contents: string;
}

export interface SetSignableDocumentStatusRequest {
  documentID: number;
  status: number;
}

export interface GetDoctorSignatureRequest {

}

export interface JRequestListRequest {
  fromDate: Date;
  toDate: Date;
}

export interface NewJRequestRequest {
  patientID: number;
}

export interface DeleteJRequestRequest {
  jRequestID: number;
}

export interface JRequestPriceListRequest {
  patientID: number;
  cabinetID: number;
}

export interface JOrdersInfoRequest {
  jRequestID: number;
}

export interface SaveJRequestRequest {
  item: JRequestEditDto;
}

export interface JRequestEditDto {
  senderDoctorID: number;
  jRequestNote: string;
  cabinetID: number;
  labMaterialID: number;
  materialNote: string;
  nrNErifFormatedNumber: string;
  services: PriceListDepartmentCM[];
  vaucherCode: string;
}

export interface PriceListDepartmentCM {
  serviceID: number;
  serviceNote: string;
  priceID: number;
  serviceCodeMkb9: string;
  labServicePackJSon: string;
  isHasChildServices: boolean;
  acCode: string;
  acName: string;
  serviceName: string;
  serviceCategoryID: number;
  serviceCategoryName: string;
  labGroupID: number;
  selectCaption: string;
  isFixedPrice: boolean;
  servicePrice: number;
  jDiscountPercent: number;
  jQuantity: number;
  jAmount: number;
  contractAmount: number;
  allAmount: number;
  jPartnerID: number;
  patientID: number;
  departmentPationID: number;
  spineID: number;
  isCheck: boolean;
  clinicProcID: number;
  ivfArtID: number;
  packJOrderID: number;
  vatGroupID: number;
  jPartnerName: string;
  discountJAmount: number;
  defaultCabinetID: number;
  isKsmpPril21a: boolean;
}

export interface JRequestReportRequest {
  jRequestID: number;
}

export interface GenerateQrCodeRequest {
  data: string;
}

export interface DoctorAgentMeetingsCatalogRequest {
  fromDate: Date;
  toDate: Date;
}

export interface GetMedicalAgentNewEditRequest {
  agentMeetingId?: number;
}

export interface SaveMedicalAgentRequest {
  item: DoctorAgentMeetingDto;
}

export interface DoctorAgentMeetingDto {
  agentMeetingID: number;
  agentDoctorid: number;
  doctorid?: number;
  meetingDatetime: Date;
  meetingPlace: string;
  meetingSubject: string;
  acodeStatusID: number;
  meetingTime: string;
}

export interface DeleteDoctorAgentMeetingRequest {
  agentMeetingId: number;
}

export interface MicrobiologyResultListRequest {
  fromDate: Date;
  toDate: Date;
  patientID?: number;
  patientIdentNumberBeginsWith?: string;
}

export interface GetMicrobiologyResultRequest {
  patientRecID: number;
}

export interface NewMicrobiologyResultRequest {

}

export interface SaveMicrobiologyResultRequest {
  item: MicrobiologyResultDto;
}

export interface MicrobiologyResultDto {
  conclusion: string;
  doctorID: number;
  examDateTime: Date;
  material: string;
  microbes: MicrobeDto[];
  microscopeExam: string;
  patientID: number;
  patientRecordID: number;
  senderDoctorID: number;
}

export interface MicrobeDto {
  antibiogrameACodeShortName: string;
  grade: string;
  antibiograme: AntibiogrameDto[];
  microbeName: string;
  quantity: string;
}

export interface AntibiogrameDto {
  aCode: string;
  aCodeShortName: string;
  aCodeName: string;
  sensitivity?: string;
}

export interface SaveCombineMicrobiologyResultRequest {
  item: CombineMicrobiologyResultDto;
}

export interface CombineMicrobiologyResultDto {
  conclusion: string;
  doctorID: number;
  examDateTime: Date;
  material: string;
  microbes: MicrobeDto[];
  microscopeExam: string;
  patientID: number;
  patientRecordID: number;
  senderDoctorID: number;
}

export interface PrintMicrobiologyResultRequest {
  patientRecID: number;
}

export interface DeleteMicrobiologyResultRequest {
  patientRecID: number;
}

export interface SearchPatientsRequest {
  patientID?: number;
  patientIdentNumberBeginsWith?: string;
  patientFirstName?: string;
  patientSecondName?: string;
  patientLastName?: string;
}

export interface PatientInformationRequest {
  patientID: number;
}

export interface NewPatientRequest {

}

export interface SavePatientRequest {
  item: NewPatientDto;
}

export interface GetPatientForEditRequest {
  patientID?: number;
  patientIdentNumber: string;
}

export interface SendPatientLoginInfoRequest {
  patientID: number;
}

export interface ListDoctorTemplatesRequest {
  templateTypeID: number;
}

export interface GetDoctorTemplateRequest {
  templateID: number;
}

export interface DeleteDoctorTemplateRequest {
  templateID: number;
}

export interface SaveDoctorTemplatesRequest {
  item: DoctorTemplateDto;
}

export interface DoctorTemplateDto {
  templateDescription: string;
  templateID: number;
  templateShortName: string;
  templateTypeID: number;
  templateTypeName: string;
}

export interface NewDoctorTemplateRequest {
  templateTypeID: number;
}

export interface SaveSessionInfoRequest {
  state: WebSessionStateModel;
}

export interface WebSessionStateModel {
  getDoctorJRequestsRequest?: GetDoctorJRequestsRequest;
  cytologyResultListRequest?: CytologyResultListRequest;
  attachedResultsListRequest?: AttachedResultsListRequest;
  microbiologyResultListRequest?: MicrobiologyResultListRequest;
  senderDoctorResultListRequest?: SenderDoctorResultListRequest;
}

export interface GetSessionInfoRequest {

}

export interface GetDicomJStudiesRequest {
  patientNames: string;
  patientUID: string;
  fromDate?: Date;
  toDate?: Date;
}

export interface GetDicomInstancesRequest {
  studyInstanceUID: string;
}

export interface SetDoctorDicomJStudyArchiveForProcessingRequest {
  studyInstanceUID: string;
  downloadEmail?: string;
}

export interface SetPatientDicomJStudyArchiveForProcessingRequest {
  studyInstanceUID: string;
  downloadEmail?: string;
}

export interface GetDoctorDicomJStudyArchiveStatusRequest {
  studyInstanceUID: string;
}

export interface GetPatientDicomJStudyArchiveStatusRequest {
  studyInstanceUID: string;
}

export interface DownloadDicomJStudyArchiveRequest {
  dicomDownloadID: number;
}

export interface GetPatientImagingRequest {

}

export interface GetPromotionsRequest {
  promotionGuid: string;
}

export interface SavePromotionDetailRequest {
  item: PromotionDto;
}

export interface PromotionDto {
  promotionID: number;
  promotionDetailID: number;
  promotionCode: string;
  promotionStatus: number;
  sendToMobile: string;
  sendToEmail: string;
  sendToNote: string;
  shouldSend: boolean;
}

export interface GetPromotionDetailRequest {
  promotionDetailID: number;
}

export interface CheckPromotionDetailRequest {
  promotionDetailCode: string;
}

export interface LoginRequest {
  password: string;
  username: string;
  rememberMe: boolean;
}

export interface LoginWithTokenRequest {
  tokenValue: string;
}

export interface ChangeUsernameAndPasswordRequest {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  newUsername: string;
}

export interface InvalidateAccountRequest {

}

export interface DoctorChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface AppointmentWorkSchedulerResponse {
  items: WAppointmentCacheCatalogShortWeb[];
}

export interface WAppointmentCacheCatalogShortWeb {
  wAppointmentPatientID: number;
  workKabinetTypeID: number;
  doctorID: number;
  doctorName: string;
  keyID: number;
  paymentType: number;
  paymentTypeName: string;
  isNzok: boolean;
  isFinFund: boolean;
  wcachefromtime: Date;
  workDate: Date;
  fromTime: Date;
  toTime: Date;
  wDuration: number;
  workKabinetTypeName: string;
  wAppointmentNote: string;
  wPatientName: string;
  isFree: boolean;
  isHide: boolean;
  isCanceled: boolean;
  isReserved: boolean;
}

export interface AppointmentCabinetsResponse {
  items: AppointmentCabinetDto[];
}

export interface AppointmentCabinetDto {
  cabinetID: number;
  cabinetShortName: string;
  cabinetFullName: string;
}

export interface AppointmentDoctorsResponse {
  items: AppointmentDoctorDto[];
}

export interface AppointmentDoctorDto {
  doctorID: number;
  doctorName: string;
  doctorFirstName: string;
  doctorSecondName: string;
  doctorLastName: string;
  doctorTitleName: string;
  workKabinetTypeID: number;
}

export interface AppointmentDaysResponse {
  items: Date[];
}

export interface AppointmentHoursResponse {
  items: AppointmentHour[];
}

export interface AppointmentHour {
  date: Date;
  isNZOK: boolean;
}

export interface MakeAppointmentResponse {
  message: string;
}

export interface EventsLoginResponse {
  login: EventsLogin;
}

export interface EventsLogoutResponse {

}

export interface EventsOnlineUsersResponse {
  sessions: LogSessionDto[];
}

export interface LogSessionDto {
  computerName: string;
  ipInfo: string;
  jversion: string;
  loginDt: Date;
  outloginDt: Date;
  sessionID: number;
  userid: number;
  userDisplayInfo: string;
}

export interface UserMessageResponse {

}

export interface IdentTypesResponse {
  identTypes: IdentTypeDto[];
}

export interface IdentTypeDto {
  identTypeID: number;
  identTypeName: string;
}

export interface LocationCountriesResponse {
  countries: LocationCountryDto[];
}

export interface LocationCountryDto {
  countryID: number;
  countryISOCode: string;
  countryBGName: string;
  countryENName: string;
}

export interface LocationStatesCitiesResponse {
  addresses: LocationStateCityDto[];
}

export interface LocationStateCityDto {
  cityID: number;
  stateName: string;
  cityName: string;
}

export interface LocationQuartersResponse {
  quarters: LocationQuarterDto[];
}

export interface LocationQuarterDto {
  id: number;
  name: string;
}

export interface GetOperationCabinetsResponse {
  operationCabinets: OperationCabinetDto[];
}

export interface OperationCabinetDto {
  grCabinetID: number;
  grCabinetName: string;
  grCabinetPosition: number;
}

export interface GetOperationTypesResponse {
  operationTypes: OperationTypeDto[];
}

export interface OperationTypeDto {
  grOperationTypeID: number;
  grOperationTypeName: string;
  grOperationTypePos: number;
}

export interface GetOperationsResponse {
  operations: GrOperationDto[];
}

export interface SaveOperationResponse {
  grOperationID: number;
}

export interface DeleteOperationResponse {

}

export interface SetCurrentUpdateStatusResponse {

}

export interface CurrentStatusResponse {

}

export interface VersionedResourcesResponse {
  textResources?: { [key: number]: string };
  aCodesVersion: number;
  globalVersion: number;
  userPrivileges?: number[];
  userPrivilegesVersion: number;
  aCodesUpdated: boolean;
  userPrivilegesUpdated: boolean;
  settings: PublicSettingsModel;
}

export interface PublicSettingsModel {
  features: string[];
  requireEgnForAppointments: boolean;
  enableFakeCaptchaForAppointments: boolean;
  serverName: string;
  appoShowAddress: boolean;
}

export interface GetMicrobiologyMaterialsResponse {
  items: SelectItem<string>[];
}

export interface GetMicrobiologyMicrobesResponse {
  items: SelectItem<string>[];
}

export interface GetMicrobiologyAntibiogramesResponse {
  items: AntibiogrameRowDto[];
}

export interface AntibiogrameRowDto {
  aCode: string;
  aCodeShortName: string;
  aCodeName: string;
}

export interface AiResultReadingResponse {
  urlToRedirect: string;
}

export interface GenerateCertificateResponse {
  certificate: SignCertificateCM;
}

export interface SignCertificateCM {
  certificateID: number;
  createDatetime: Date;
  doctorid: number;
  fileID: number;
  patientid: number;
  pinCode: string;
  validateToDate: Date;
}

export interface GetCertificateResponse {
  certificate: SignCertificateCM;
}

export interface GetRsaDocumentsResponse {
  signedDocs: SignDocCM[];
  notSignedDocs: SignDocCM[];
  hasCertificate: boolean;
}

export interface SignDocCM {
  signDocID: number;
  docName: string;
  requestDatetime: Date;
  signStatus: number;
  statusName: string;
  requestFilePathID: number;
  signedFilePathID: number;
}

export interface SignRsaDocumentResponse {

}

export interface GetRsaDocumentPreviewResponse {
  title: string;
  pdfContent: string;
  isDocumentSigned: boolean;
}

export interface DeleteCertificateResponse {

}

export interface SignPdfResponse {
  signedPdfBase64: string;
}

export interface CheckPdfSigningResponse {
  response: string;
}

export interface DigitalSignPdfResponse {

}

export interface PatientAppointmentDelayResponse {
  item: AppointmentDelayDto;
}

export interface AppointmentDelayDto {
  wAppointmentFromDateTime: Date;
  calculatednum: number;
  patientid: number;
  doctorname: string;
  prevPatients: number;
  expectedTime: Date;
  lateMinutes: number;
  hasJRequest: boolean;
}

export interface PatientBillingInformationResponse {
  items: BillingResponseItem[];
  invoices: BillingInvoiceItem[];
  advancedValue: number;
}

export interface BillingResponseItem {
  hospitalInfoID: number;
  hospitalName: string;
  patientRestPrice: number;
}

export interface BillingInvoiceItem {
  fDocID: number;
  fDocDate: Date;
  fDocNum: number;
}

export interface PatientBillingInvoiceDownloadResponse {
  invoicePdf: string;
}

export interface SaveFirebasePersonalTokenResponse {

}

export interface ConsultGetJRequestsResponse {
  jRequests: ConsultationJRequestDto[];
}

export interface ConsultationJRequestDto {
  jRequestID: number;
  cabinetName: string;
  doctorName: string;
  jRequestDateTime: Date;
  isPaid: boolean;
  cabinetTypeID: number;
}

export interface ConsultGetCabinetsDoctorsResponse {
  items: ConsultationCabinetDoctorDto[];
}

export interface ConsultationCabinetDoctorDto {
  valueMember: string;
  displayMember: string;
  cabinetID: number;
  doctorID: number;
  cabinetTypeID: number;
}

export interface ConsultGetCabinetAppointmentsResponse {
  items: ConsultationCabinetAppointmentDto[];
}

export interface ConsultationCabinetAppointmentDto {
  workAppMetaID: number;
  appointmentFromDateTime: Date;
  displayMember: string;
}

export interface ConsultGetPriceListResponse {
  items: ConsultationPriceItemDto[];
}

export interface ConsultationPriceItemDto {
  serviceID: number;
  serviceCategoryName: string;
  serviceName: string;
  servicePrice: number;
}

export interface ConsultSaveJRequestResponse {

}

export interface ConsultDeleteJRequestResponse {

}

export interface ConsultSaveAttachedResultResponse {

}

export interface ConsultDeleteAttachedResultResponse {

}

export interface PatientRegisterResponse {

}

export interface ValidatePatientInformationResponse {

}

export interface PatientPersonalDataResponse {
  patient: PatientDisplayDto;
}

export interface PatientDisplayDto {
  patientID: number;
  patientMobile: string;
  patientIdentNumber: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientBirthDay?: Date;
  patientAddress: string;
  patientQuarter: string;
  patientCity: string;
  patientContactAddress: string;
  patientEmail: string;
  discountPercent: number;
  patientNote: string;
  age: number;
  regionName: string;
}

export interface GetPatientSignedDocumentResponse {
  base64Content: string;
  documentTitle: string;
}

export interface GetPatientSignedDocumentsListResponse {
  signedDocuments: PatientSignedDocumentDto[];
}

export interface PatientOwnInformationResponse {
  item: PatientOwnInformationDto;
}

export interface PatientOwnInformationDto {
  patientFullName: string;
  patientMobile: string;
  patientIdentNumber: string;
  patientID: number;
}

export interface PatientPrescriptionsResponse {
  prescriptionDtos: PatientPrescriptionDto[];
}

export interface PatientPrescriptionDto {
  dcDynDocID: number;
  doctorFirstLastName: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientFirstLastName: string;
  dcDynDocDateTime: Date;
  dcDynDocDateFormated: string;
  formatedMedicaments: string;
}

export interface PatientPrescriptionPdfResponse {
  fileContainBase64: string;
  fileName: string;
}

export interface GetAdResponse {
  item: AdDto;
}

export interface AdDto {
  acodeID: number;
  banerPosition: number;
  imageBase64: string;
  banerUrl: string;
}

export interface MarkAdAsDisplayedResponse {

}

export interface MarkAdAsClickedResponse {

}

export interface PatientRestorePasswordResponse {

}

export interface PatientLabResultsResponse {
  items: PatientRecordDto[];
  isInDebt: boolean;
}

export interface PatientRecordDto {
  patientID: number;
  patientIdentNumber: string;
  patientFullName: string;
  materialBarcodes: string;
  dateJRequestDateTime: Date;
  datePatientRecDateTime?: Date;
  jRequestID: number;
  patientRecID?: number;
  patientRecShortName: string;
  onlineDisplayName: string;
  recordTypeName: string;
  collectionName: string;
  recordTypeID?: number;
  patientRecStatus?: number;
  doctorShortName: string;
  docID: string;
  parseStatus: string;
  hasPatientRecord: boolean;
  isEnableAI: boolean;
  senderDoctorName: string;
  groupConcatServiceNames: string;
  customReadyStatus: number;
  locationName: string;
  uniqueRecordPrefix: string;
  ambDocumentID: number;
  onlineDateTime: Date;
  documentLanguageType: number;
  hasEnglishDocument: boolean;
  dicomImages: DicomImageItem[];
  ambDocID: number;
}

export interface DicomImageItem {
  dcmWorklistID: number;
  studyInstanceUID: string;
  description: string;
  patientRecID: number;
  statusID: number;
  dicomDownloadID: number;
  patientNames: string;
  patientIdentNumber: string;
}

export interface PatientResultResponse {
  pdfContent?: string;
  title?: string;
  isInDebt: boolean;
}

export interface PublicPatientResultResponse {
  pdfContent?: string;
  title?: string;
}

export interface DynamicPatientDossieGetResponse {
  pdfContent: string;
}

export interface MergePdfsResponse {
  pdfResultBase64: string;
}

export interface GetPatientInformedConsentPdfResponse {
  pdfContent: string;
}

export interface GetViberQrResponse {
  qrCode: string;
  deepLink: string;
}

export interface GetViberPubliQrResponse {
  qrCode: string;
  deepLink: string;
}

export interface PatientObligationsResponse {
  obligations: PatientObligation[];
}

export interface PatientObligation {
  obligationKey: string;
  jOrderID: number;
  obligationInfo: string;
  serviceName: string;
  forPay: number;
  forPayActual: number;
  forPayTotal: number;
  usnID: number;
  invoiceFDocID: number;
  rankID: number;
  patientID: number;
  serviceID: number;
  hospitalInfoID: number;
  hospitalName: string;
  isCheck: boolean;
}

export interface PatientCartsResponse {
  carts: PatientCart[];
}

export interface PatientCart {
  cartID: number;
  cartStatusID: number;
  cartStatusName: string;
  isWaiting: boolean;
  isPaid: boolean;
  isRejected: boolean;
  isStorniran: boolean;
  transactionDatetime: Date;
  amount: number;
  orderID: string;
  servicesNames: string;
}

export interface PatientCreateCartResponse {
  resultData: string;
  cartType: number;
}

export interface PatientCheckCartStatusResponse {
  cart: PatientCart;
}

export interface PatientStornoCartResponse {

}

export interface ProcessCartPaymentResponse {
  orderID: string;
}

export interface PatientVisitsResponse {
  appointments: PatientWAppointment[];
  days: VisitDay[];
}

export interface PatientWAppointment {
  visitDateTime: Date;
  visitDateTimeFormated: string;
  calculatedNumFormated: string;
  canseledFormated: string;
  wAppointmentPatientID: number;
  isCanceled: boolean;
  calculatedNum: number;
  doctorName: string;
  workKabinetTypeName: string;
}

export interface VisitDay {
  date: Date;
  visits: PatientVisit[];
}

export interface PatientVisit {
  visitDateTime: Date;
  title: string;
  content: string;
}

export interface JabchoSettingsResponse {
  jabchoOneSignalApplicationID: string;
}

export interface JabchoLoginResponse {
  loginID: number;
}

export interface JabchoLogoutResponse {

}

export interface NotificationPatientsResponse {
  patientIDs: number[];
}

export interface SendNotificationResponse {
  oneSignalResponseJson: string;
}

export interface PatientQuestionsDoctorsResponse {
  doctors: PatientQuestionDoctorDto[];
}

export interface PatientQuestionDoctorDto {
  doctorID: number;
  doctorFullName: string;
}

export interface PatientAskQuestionResponse {

}

export interface PatientQuestionsListResponse {
  notifications: PatientQuestionDto[];
}

export interface PatientQuestionDto {
  mContent: string;
  mTypeID: number;
  mCreateDateTime: Date;
  doctor: PatientQuestionDoctorDto;
}

export interface DisplayAppointmentResponse {
  items: CabinetDisplayCM[];
}

export interface CabinetDisplayCM {
  doctorFirstLastName: string;
  modifiedFromUserName: string;
  formatedCurrentNumber: string;
  doctorFirstName: string;
  doctorSecondName: string;
  doctorLastName: string;
  doctorTitleName: string;
  displayid: number;
  displayName: string;
  displayDescription: string;
  currentNumber: number;
  isBusy: boolean;
  doctorID: number;
  activityName: string;
  displayDateTime: Date;
  userID: number;
  sortPosition: number;
  panelNumber: number;
  customText: string;
}

export interface AttachedResultsListResponse {
  items: PatientRecordDto[];
}

export interface SaveAttachedResultResponse {
  patientRecordID: number;
}

export interface GetAttachedResultResponse {
  item: AttachedResultDto;
}

export interface NewAttachedResultResponse {
  item: AttachedResultDto;
}

export interface DeleteAttachedResultResponse {

}

export interface CytologyNomenclaturesResponse {
  testMethods: SelectItem<number>[];
  papanikoauGroups: SelectItem<number>[];
  categoryBValues: SelectItem<number>[];
  categoryCValues: SelectItem<number>[];
  categoryDValues: SelectItem<number>[];
  categoryEValues: SelectItem<number>[];
  adekvatnostBValues: SelectItem<number>[];
  adekvatnostVValues: SelectItem<number>[];
  adekvatnostGValues: SelectItem<number>[];
  nextExamListValues: SelectItem<number>[];
  recommendationsValues: SelectItem<number>[];
}

export interface CytologyResultListResponse {
  items: PatientRecordDto[];
}

export interface GetCytologyResultResponse {
  item: CytologyResultDto;
}

export interface NewCytologyResultResponse {
  item: CytologyResultDto;
}

export interface SaveCytologyResultResponse {
  patientRecordID: number;
}

export interface PrintCytologyResultResponse {
  pdfFileContent: string;
  pdfFileTitle: string;
  patientRecordID: number;
}

export interface DeleteCytologyResultResponse {

}

export interface DoctorCatalogResponse {
  items: DoctorCatalogDto[];
}

export interface DoctorCatalogDto {
  doctorID: number;
  doctorUIN: string;
  doctorCategoryName: string;
  doctorCategoryPosition: number;
  doctorTitleName: string;
  doctorFirstName: string;
  doctorSecondName: string;
  doctorLastName: string;
  doctorMobile: string;
  doctorFirstLastName: string;
  doctorUnitName: string;
}

export interface GetDoctorJRequestsResponse {
  items: PatientRecordDto[];
}

export interface GetJRequestResponse {
  item: JRequestDto;
}

export interface JRequestDto {
  jRequestID: number;
  patientRecordID?: number;
  patientID: number;
}

export interface SenderDoctorResultListResponse {
  items: PatientRecordDto[];
  dicomImages: PatientImagingDto[];
}

export interface PatientImagingDto {
  patientRecID: number;
  patientRecDateTime: Date;
  patientRecShortName: string;
  patientFullName: string;
  patientIdentNumber: string;
  dcmWorklistID: number;
  scheduledProcedureDateTime: Date;
  modality: string;
  studyInstanceUID: string;
  type: number;
  description: string;
  statusID: number;
  dicomDownloadID: number;
  isHasStudy: boolean;
  departmentpationid: number;
}

export interface SenderDoctorResultsViewResponse {
  items: PatientRecordDto[];
  pdfContent: string;
  title: string;
}

export interface DoctorScheduleResponse {
  appointments: WAppointmentDto[];
  operations: DoctorScheduleOperation[];
}

export interface WAppointmentDto {
  appotypename: string;
  casteddoctororderposition: string;
  doctorfirstlastname: string;
  doctorid: number;
  doctorspecialtycode: string;
  doctorspecialtyid: number;
  doctorspecialtyname: string;
  timeFormated: string;
  wappointmentpatientid: number;
  wcachefromtime: Date;
  workdate: Date;
  workkabinettypeid: number;
  workkabinettypename: string;
  worknote: string;
  isfree: boolean;
  isreserved: boolean;
  wpatientmobile: string;
  wpatientname: string;
  workappmetaid: number;
  duration: number;
  wappointmentnote: string;
  timeAndWorkcabientFormated: string;
  paymentType: number;
  paymentTypeName: string;
  isNZOK: boolean;
  buttonCaption: string;
}

export interface DoctorScheduleOperation {
  grOperationPatientName: string;
  grStartDateTime: Date;
  grOperationTypeName: string;
  grCabinetName: string;
  grDescription: string;
  position: number;
  grStartDateTimeStr: string;
}

export interface GetSignableDocumentsListResponse {
  items: SignableDocumentDto[];
}

export interface SignableDocumentDto {
  documentID: number;
  documentName: string;
  signed: boolean;
  documentContent: string;
  signedText: string;
}

export interface GetNextDocumentToSignResponse {
  signDocID?: number;
}

export interface GetSignableDocumentResponse {
  item: SignableDocumentDto;
  signingElement: SigningElement[];
  pageWidth: number;
}

export interface SigningElement {
  signingElementWidth: number;
  signingElementHeight: number;
  signingElementTop: number;
  signingElementLeft: number;
  pageNumber: number;
  pageWidth: number;
}

export interface SaveSignableDocumentResponse {

}

export interface SetSignableDocumentStatusResponse {

}

export interface GetDoctorSignatureResponse {
  signatureImageContent: string;
}

export interface JRequestListResponse {
  items: JRequestListDto[];
}

export interface JRequestListDto {
  jRequestID: number;
  autoID: number;
  createDateTime: Date;
  jRequestDateTime: Date;
  jRequestDate: Date;
  patientID: number;
  senderDoctorID: number;
  senderDoctorName: string;
  doctorID: number;
  doctorName: string;
  jRequestStatusID: number;
  jRequestStatusName: string;
  patientStatusID: number;
  jSonContainType: string;
  jSonMeta: string;
  hasPaperRequst: boolean;
  hospitalInfoID: number;
  ver: number;
  createdByUserID: number;
  modifiedByUserID: number;
  cabinetID: number;
  acceptDateTime: Date;
  departmentPationID: number;
  clinicProcID: number;
  ivfArtID: number;
  prevCycleID: number;
  jRequestDayNum: number;
  jRequestDayNumDisplay: string;
  jPartnerID: number;
  jRequestNote: string;
  viewInfo: string;
  isCanAddOrder: boolean;
  userName: string;
  patientFullName: string;
  patientIdentNumber: string;
  cabinetName: string;
  jPartnerName: string;
  isPaid: boolean;
  isPaidCaption: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientCity: string;
  patientState: string;
  labStatusID: number;
  isLabAccepted: boolean;
  labStatusName: string;
  materialBarCodeConcat: string;
  concatStatusJReqLab: string;
  priceID: number;
  priceName: string;
  jPartnerPriceName: string;
}

export interface NewJRequestResponse {
  item: JRequestEditDto;
  patientInfo: string;
  cabinets: JUtilyValue[];
  materials: JUtilyValue[];
  patientID: number;
}

export interface JUtilyValue {
  valueMember: number;
  displayMember: string;
  optionalValue: string;
}

export interface DeleteJRequestResponse {

}

export interface JRequestPriceListResponse {
  items: PriceListDepartmentCM[];
}

export interface JOrdersInfoResponse {
  message: string;
}

export interface SaveJRequestResponse {
  jRequestID: number;
}

export interface JRequestReportResponse {
  pdfFileContent: string;
  pdfFileTitle: string;
}

export interface GenerateQrCodeResponse {
  qrCode: string;
}

export interface DoctorAgentMeetingsCatalogResponse {
  medicalAgentDisplayName: string;
  items: MedicalAgentCatalogDto[];
}

export interface MedicalAgentCatalogDto {
  agentMeetingID: number;
  meetingDatetime: Date;
  doctorfullname: string;
  meetingPlace: string;
  meetingSubject: string;
  status: string;
}

export interface GetMedicalAgentNewEditResponse {
  item: DoctorAgentMeetingDto;
  medicalAgentsSelectItems: SelectItem<number>[];
  meetingStatuses: SelectItem<number>[];
}

export interface SaveMedicalAgentResponse {
  agentMeetingId: number;
}

export interface DeleteDoctorAgentMeetingResponse {

}

export interface MicrobiologyResultListResponse {
  items: PatientRecordDto[];
}

export interface GetMicrobiologyResultResponse {
  item: MicrobiologyResultDto;
}

export interface NewMicrobiologyResultResponse {
  item: MicrobiologyResultDto;
}

export interface SaveMicrobiologyResultResponse {
  patientRecID: number;
}

export interface SaveCombineMicrobiologyResultResponse {
  patientRecID: number;
}

export interface PrintMicrobiologyResultResponse {
  pdfFileContent: string;
  pdfFileTitle: string;
  patientRecID: number;
}

export interface DeleteMicrobiologyResultResponse {

}

export interface SearchPatientsResponse {
  items: PatientInformationDto[];
}

export interface PatientInformationDto {
  patientID: number;
  patientMobile: string;
  patientIdentNumber: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientBirthDay?: Date;
  patientAddress: string;
  patientQuarter: string;
  patientCity: string;
  patientNote: string;
  patientAge: number;
  patientFullName: string;
  patientFullAddress: string;
}

export interface PatientInformationResponse {
  item: PatientInformationDto;
}

export interface NewPatientResponse {
  item: NewPatientDto;
}

export interface SavePatientResponse {
  item: PatientInformationDto;
}

export interface GetPatientForEditResponse {
  item?: NewPatientDto;
}

export interface SendPatientLoginInfoResponse {

}

export interface ListDoctorTemplatesResponse {
  items: DoctorTemplateDto[];
  templateTypeName: string;
  templateTypeID: number;
}

export interface GetDoctorTemplateResponse {
  item: DoctorTemplateDto;
}

export interface DeleteDoctorTemplateResponse {

}

export interface SaveDoctorTemplatesResponse {
  templateID: number;
}

export interface NewDoctorTemplateResponse {
  item: DoctorTemplateDto;
}

export interface SaveSessionInfoResponse {

}

export interface GetSessionInfoResponse {
  state: WebSessionStateModel;
}

export interface GetDicomJStudiesResponse {
  dicomStudies: DicomJStudyCM[];
}

export interface DicomJStudyCM {
  dcmID: number;
  patientID: number;
  departmentPationID: number;
  clinicProcID: number;
  studyInstanceUid: string;
  studyID: string;
  accessionNumber: string;
  studyDate: Date;
  studyTime: Date;
  studyDateTime: Date;
  studyDescription: string;
  modalitiesInStudy: string;
  patientName: string;
  patientUID: string;
  patientBirthDate: Date;
  issuerOfPatientID: string;
  patientSex: string;
  otherPatientIDs: string;
  seriesCount: number;
  instancesCount: number;
  hasVideo: boolean;
  isStable: boolean;
  dcmWorkStationID: number;
  studyLastUpdateTime: Date;
  identTypeID: number;
  patientIdentNumber: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientFullName: string;
  imagingConclusion: string;
  studyDescriptionBind: string;
}

export interface GetDicomInstancessResponse {
  wadoURIs: string[];
  dicomInstancePreviews: DicomInstancePreview[];
}

export interface DicomInstancePreview {
  instanceID: number;
  imagePreview: string;
}

export interface SetDicomJStudyArchiveForProcessingResponse {

}

export interface GetDicomJStudyArchiveStatusResponse {
  statusID: number;
  dicomDownloadID: number;
}

export interface DownloadDicomJStudyArchiveResponse {
  dicomEndpoint: string;
  archiveName: string;
}

export interface GetPatientImagingResponse {
  patientImagingDto: PatientImagingDto[];
}

export interface GetPromotionsResponse {
  promotionID: number;
  promotionName: string;
  promotionDescription: string;
  items: PromotionDto[];
  countDistributed: number;
  countUsed: number;
  countInvalid: number;
  hasExpired: boolean;
  jPartnerName: string;
}

export interface SavePromotionDetailResponse {

}

export interface GetPromotionDetailResponse {
  item: PromotionDto;
}

export interface CheckPromotionDetailResponse {
  item: PromotionDetailDto;
}

export interface PromotionDetailDto {
  promotionName: string;
  promotionDescription: string;
  jPartnerName: string;
  promotionStatus: number;
  promotionCode: string;
  promotionNoteForUsers: string;
}

export interface LoginResponse {
  token: string;
  userDisplayName: string;
  userRole: number;
  userPrivileges?: number[];
  userID: number;
  patientID: number;
  webSessionID: number;
  doctorID: number;
  username: string;
}

export interface ChangeUsernameAndPasswordResponse {

}

export interface InvalidateAccountResponse {

}

export interface DoctorChangePasswordResponse {

}

export interface ServerApiClient {
  appointmentWorkScheduler: (req: AppointmentWorkSchedulerRequest) => Promise<Result<AppointmentWorkSchedulerResponse>>;
  appointmentCabinets: (req: AppointmentCabinetsRequest) => Promise<Result<AppointmentCabinetsResponse>>;
  appoCabinets: (req: AppoCabinetsRequest) => Promise<Result>;
  appointmentDoctors: (req: AppointmentDoctorsRequest) => Promise<Result<AppointmentDoctorsResponse>>;
  appoDoctors: (req: AppoDoctorsRequest) => Promise<Result>;
  appointmentDays: (req: AppointmentDaysRequest) => Promise<Result<AppointmentDaysResponse>>;
  appoDays: (req: AppoDaysRequest) => Promise<Result>;
  appointmentHours: (req: AppointmentHoursRequest) => Promise<Result<AppointmentHoursResponse>>;
  appoHours: (req: AppoHoursRequest) => Promise<Result>;
  makeAppointment: (req: MakeAppointmentRequest) => Promise<Result<MakeAppointmentResponse>>;
  appoMake: (req: AppoMakeRequest) => Promise<Result>;
  appoDelete: (req: AppoDeleteRequest) => Promise<Result>;
  appoGet: (req: AppoGetRequest) => Promise<Result>;
  eventsSend: (req: EventsSendMessage) => Promise<Result>;
  eventsSendMessageBr: (req: EventsSendMessageBroadcast) => Promise<Result>;
  eventsLogin: (req: EventsLoginRequest) => Promise<Result<EventsLoginResponse>>;
  eventsLogout: (req: EventsLogoutRequest) => Promise<Result<EventsLogoutResponse>>;
  eventsOnlineUsers: (req: EventsOnlineUsersRequest) => Promise<Result<EventsOnlineUsersResponse>>;
  userMessage: (req: UserMessageRequest) => Promise<Result<UserMessageResponse>>;
  firstContact: (req: FirstContactRequest) => Promise<Result>;
  clientLogin: (req: ClientLoginRequest) => Promise<Result>;
  inventoryEmailReport: (req: InventoryEmailReportRequest) => Promise<Result>;
  identTypes: (req: IdentTypesRequest) => Promise<Result<IdentTypesResponse>>;
  locationCountries: (req: LocationCountriesRequest) => Promise<Result<LocationCountriesResponse>>;
  locationStatesCities: (req: LocationStatesCitiesRequest) => Promise<Result<LocationStatesCitiesResponse>>;
  locationQuarters: (req: LocationQuartersRequest) => Promise<Result<LocationQuartersResponse>>;
  getOperationCabinets: (req: GetOperationCabinetsRequest) => Promise<Result<GetOperationCabinetsResponse>>;
  getOperationTypes: (req: GetOperationTypesRequest) => Promise<Result<GetOperationTypesResponse>>;
  getOperations: (req: GetOperationsRequest) => Promise<Result<GetOperationsResponse>>;
  saveOperation: (req: SaveOperationRequest) => Promise<Result<SaveOperationResponse>>;
  deleteOperation: (req: DeleteOperationRequest) => Promise<Result<DeleteOperationResponse>>;
  setCurrentUpdateStatus: (req: SetCurrentUpdateStatusRequest) => Promise<Result<SetCurrentUpdateStatusResponse>>;
  currentStatus: (req: CurrentStatusRequest) => Promise<Result<CurrentStatusResponse>>;
  currentVersion: (req: CurrentVersionRequest) => Promise<Result>;
  versionedResources: (req: VersionedResourcesRequest) => Promise<Result<VersionedResourcesResponse>>;
  getMicrobiologyMaterials: (req: GetMicrobiologyMaterialsRequest) => Promise<Result<GetMicrobiologyMaterialsResponse>>;
  getMicrobiologyMicrobes: (req: GetMicrobiologyMicrobesRequest) => Promise<Result<GetMicrobiologyMicrobesResponse>>;
  getMicrobiologyAntibiogrames: (req: GetMicrobiologyAntibiogramesRequest) => Promise<Result<GetMicrobiologyAntibiogramesResponse>>;
  aiResultReading: (req: AiResultReadingRequest) => Promise<Result<AiResultReadingResponse>>;
  generateCertificate: (req: GenerateCertificateRequest) => Promise<Result<GenerateCertificateResponse>>;
  getCertificate: (req: GetCertificateRequest) => Promise<Result<GetCertificateResponse>>;
  getRsaDocuments: (req: GetRsaDocumentsRequest) => Promise<Result<GetRsaDocumentsResponse>>;
  signRsaDocument: (req: SignRsaDocumentRequest) => Promise<Result<SignRsaDocumentResponse>>;
  getRsaDocumentPreview: (req: GetRsaDocumentPreviewRequest) => Promise<Result<GetRsaDocumentPreviewResponse>>;
  deleteCertificate: (req: DeleteCertificateRequest) => Promise<Result<DeleteCertificateResponse>>;
  signPdf: (req: SignPdfRequest) => Promise<Result<SignPdfResponse>>;
  checkPdfSigning: (req: CheckPdfSigningRequest) => Promise<Result<CheckPdfSigningResponse>>;
  digitalSignPdf: (req: DigitalSignPdfRequest) => Promise<Result<DigitalSignPdfResponse>>;
  patientAppointmentDelay: (req: PatientAppointmentDelayRequest) => Promise<Result<PatientAppointmentDelayResponse>>;
  patientBillingInformation: (req: PatientBillingInformationRequest) => Promise<Result<PatientBillingInformationResponse>>;
  patientBillingInvoiceDownload: (req: PatientBillingInvoiceDownloadRequest) => Promise<Result<PatientBillingInvoiceDownloadResponse>>;
  saveFirebasePersonalToken: (req: SaveFirebasePersonalTokenRequest) => Promise<Result<SaveFirebasePersonalTokenResponse>>;
  patientNotifications: (req: PatientNotificationsRequest) => Promise<Result>;
  consultGetJRequests: (req: ConsultGetJRequestsRequest) => Promise<Result<ConsultGetJRequestsResponse>>;
  consultGetCabinetsDoctors: (req: ConsultGetCabinetsDoctorsRequest) => Promise<Result<ConsultGetCabinetsDoctorsResponse>>;
  consultGetCabinetAppointments: (req: ConsultGetCabinetAppointmentsRequest) => Promise<Result<ConsultGetCabinetAppointmentsResponse>>;
  consultGetPriceList: (req: ConsultGetPriceListRequest) => Promise<Result<ConsultGetPriceListResponse>>;
  consultSaveJRequest: (req: ConsultSaveJRequestRequest) => Promise<Result<ConsultSaveJRequestResponse>>;
  consultDeleteJRequest: (req: ConsultDeleteJRequestRequest) => Promise<Result<ConsultDeleteJRequestResponse>>;
  consultSaveAttachedResult: (req: ConsultSaveAttachedResultRequest) => Promise<Result<ConsultSaveAttachedResultResponse>>;
  consultDeleteAttachedResult: (req: ConsultDeleteAttachedResultRequest) => Promise<Result<ConsultDeleteAttachedResultResponse>>;
  patientRegister: (req: PatientRegisterRequest) => Promise<Result<PatientRegisterResponse>>;
  validatePatientInformation: (req: ValidatePatientInformationRequest) => Promise<Result<ValidatePatientInformationResponse>>;
  patientPersonalData: (req: PatientPersonalDataRequest) => Promise<Result<PatientPersonalDataResponse>>;
  getPatientSignedDocument: (req: GetPatientSignedDocumentRequest) => Promise<Result<GetPatientSignedDocumentResponse>>;
  getPatientSignedDocumentsList: (req: GetPatientSignedDocumentsListRequest) => Promise<Result<GetPatientSignedDocumentsListResponse>>;
  patientOwnInformation: (req: PatientOwnInformationRequest) => Promise<Result<PatientOwnInformationResponse>>;
  patientPrescriptions: (req: PatientPrescriptionsRequest) => Promise<Result<PatientPrescriptionsResponse>>;
  patientPrescriptionPdf: (req: PatientPrescriptionPdfRequest) => Promise<Result<PatientPrescriptionPdfResponse>>;
  getAd: (req: GetAdRequest) => Promise<Result<GetAdResponse>>;
  markAdAsDisplayed: (req: MarkAdAsDisplayedRequest) => Promise<Result<MarkAdAsDisplayedResponse>>;
  markAdAsClicked: (req: MarkAdAsClickedRequest) => Promise<Result<MarkAdAsClickedResponse>>;
  patientRestorePassword: (req: PatientRestorePasswordRequest) => Promise<Result<PatientRestorePasswordResponse>>;
  patientLabResults: (req: PatientLabResultsRequest) => Promise<Result<PatientLabResultsResponse>>;
  patientResult: (req: PatientResultRequest) => Promise<Result<PatientResultResponse>>;
  publicPatientResult: (req: PublicPatientResultRequest) => Promise<Result<PublicPatientResultResponse>>;
  dynamicPatientDossieGet: (req: DynamicPatientDossieGetRequest) => Promise<Result<DynamicPatientDossieGetResponse>>;
  mergePdfs: (req: MergePdfsRequest) => Promise<Result<MergePdfsResponse>>;
  getPatientInformedConsentPdf: (req: GetPatientInformedConsentPdfRequest) => Promise<Result<GetPatientInformedConsentPdfResponse>>;
  getViberQr: (req: GetViberQrRequest) => Promise<Result<GetViberQrResponse>>;
  getViberPublicQr: (req: GetViberPublicQrRequest) => Promise<Result<GetViberPubliQrResponse>>;
  patientObligations: (req: PatientObligationsRequest) => Promise<Result<PatientObligationsResponse>>;
  patientCarts: (req: PatientCartsRequest) => Promise<Result<PatientCartsResponse>>;
  patientCreateCart: (req: PatientCreateCartRequest) => Promise<Result<PatientCreateCartResponse>>;
  patientCheckCartStatus: (req: PatientCheckCartStatusRequest) => Promise<Result<PatientCheckCartStatusResponse>>;
  patientStornoCart: (req: PatientStornoCartRequest) => Promise<Result<PatientStornoCartResponse>>;
  processCartPayment: (req: ProcessCartPaymentRequest) => Promise<Result<ProcessCartPaymentResponse>>;
  patientVisits: (req: PatientVisitsRequest) => Promise<Result<PatientVisitsResponse>>;
  cancellationAppointment: (req: CancellationAppointmentRequest) => Promise<Result>;
  jabchoSettings: (req: JabchoSettingsRequest) => Promise<Result<JabchoSettingsResponse>>;
  jabchoLogin: (req: JabchoLoginRequest) => Promise<Result<JabchoLoginResponse>>;
  jabchoLogout: (req: JabchoLogoutRequest) => Promise<Result<JabchoLogoutResponse>>;
  notificationPatients: (req: NotificationPatientsRequest) => Promise<Result<NotificationPatientsResponse>>;
  sendNotification: (req: SendNotificationRequest) => Promise<Result<SendNotificationResponse>>;
  patientQuestionsDoctors: (req: PatientQuestionsDoctorsRequest) => Promise<Result<PatientQuestionsDoctorsResponse>>;
  patientAskQuestion: (req: PatientAskQuestionRequest) => Promise<Result<PatientAskQuestionResponse>>;
  patientQuestionsList: (req: PatientQuestionsListRequest) => Promise<Result<PatientQuestionsListResponse>>;
  displayAppointment: (req: DisplayAppointmentRequest) => Promise<Result<DisplayAppointmentResponse>>;
  attachedResultsList: (req: AttachedResultsListRequest) => Promise<Result<AttachedResultsListResponse>>;
  saveAttachedResult: (req: SaveAttachedResultRequest) => Promise<Result<SaveAttachedResultResponse>>;
  getAttachedResult: (req: GetAttachedResultRequest) => Promise<Result<GetAttachedResultResponse>>;
  newAttachedResult: (req: NewAttachedResultRequest) => Promise<Result<NewAttachedResultResponse>>;
  deleteAttachedResult: (req: DeleteAttachedResultRequest) => Promise<Result<DeleteAttachedResultResponse>>;
  cytologyNomenclatures: (req: CytologyNomenclaturesRequest) => Promise<Result<CytologyNomenclaturesResponse>>;
  cytologyResultList: (req: CytologyResultListRequest) => Promise<Result<CytologyResultListResponse>>;
  getCytologyResult: (req: GetCytologyResultRequest) => Promise<Result<GetCytologyResultResponse>>;
  newCytologyResult: (req: NewCytologyResultRequest) => Promise<Result<NewCytologyResultResponse>>;
  saveCytologyResult: (req: SaveCytologyResultRequest) => Promise<Result<SaveCytologyResultResponse>>;
  printCytologyResult: (req: PrintCytologyResultRequest) => Promise<Result<PrintCytologyResultResponse>>;
  deleteCytologyResult: (req: DeleteCytologyResultRequest) => Promise<Result<DeleteCytologyResultResponse>>;
  doctorCatalog: (req: DoctorCatalogRequest) => Promise<Result<DoctorCatalogResponse>>;
  getDoctorJRequests: (req: GetDoctorJRequestsRequest) => Promise<Result<GetDoctorJRequestsResponse>>;
  getJRequest: (req: GetJRequestRequest) => Promise<Result<GetJRequestResponse>>;
  senderDoctorResultList: (req: SenderDoctorResultListRequest) => Promise<Result<SenderDoctorResultListResponse>>;
  senderDoctorResultView: (req: SenderDoctorResultViewRequest) => Promise<Result<SenderDoctorResultsViewResponse>>;
  doctorSchedule: (req: DoctorScheduleRequest) => Promise<Result<DoctorScheduleResponse>>;
  doctorAppoMake: (req: DoctorAppoMakeRequest) => Promise<Result>;
  getSignableDocumentsList: (req: GetSignableDocumentsListRequest) => Promise<Result<GetSignableDocumentsListResponse>>;
  getNextDocumentToSign: (req: GetNextDocumentToSignRequest) => Promise<Result<GetNextDocumentToSignResponse>>;
  getSignableDocument: (req: GetSignableDocumentRequest) => Promise<Result<GetSignableDocumentResponse>>;
  saveSignableDocument: (req: SaveSignableDocumentRequest) => Promise<Result<SaveSignableDocumentResponse>>;
  setSignableDocumentStatus: (req: SetSignableDocumentStatusRequest) => Promise<Result<SetSignableDocumentStatusResponse>>;
  getDoctorSignature: (req: GetDoctorSignatureRequest) => Promise<Result<GetDoctorSignatureResponse>>;
  jRequestList: (req: JRequestListRequest) => Promise<Result<JRequestListResponse>>;
  newJRequest: (req: NewJRequestRequest) => Promise<Result<NewJRequestResponse>>;
  deleteJRequest: (req: DeleteJRequestRequest) => Promise<Result<DeleteJRequestResponse>>;
  jRequestPriceList: (req: JRequestPriceListRequest) => Promise<Result<JRequestPriceListResponse>>;
  jOrdersInfo: (req: JOrdersInfoRequest) => Promise<Result<JOrdersInfoResponse>>;
  saveJRequest: (req: SaveJRequestRequest) => Promise<Result<SaveJRequestResponse>>;
  jRequestReport: (req: JRequestReportRequest) => Promise<Result<JRequestReportResponse>>;
  generateQrCode: (req: GenerateQrCodeRequest) => Promise<Result<GenerateQrCodeResponse>>;
  doctorAgentMeetingsCatalog: (req: DoctorAgentMeetingsCatalogRequest) => Promise<Result<DoctorAgentMeetingsCatalogResponse>>;
  getMedicalAgentNewEdit: (req: GetMedicalAgentNewEditRequest) => Promise<Result<GetMedicalAgentNewEditResponse>>;
  saveMedicalAgent: (req: SaveMedicalAgentRequest) => Promise<Result<SaveMedicalAgentResponse>>;
  deleteDoctorAgentMeeting: (req: DeleteDoctorAgentMeetingRequest) => Promise<Result<DeleteDoctorAgentMeetingResponse>>;
  microbiologyResultList: (req: MicrobiologyResultListRequest) => Promise<Result<MicrobiologyResultListResponse>>;
  getMicrobiologyResult: (req: GetMicrobiologyResultRequest) => Promise<Result<GetMicrobiologyResultResponse>>;
  newMicrobiologyResult: (req: NewMicrobiologyResultRequest) => Promise<Result<NewMicrobiologyResultResponse>>;
  saveMicrobiologyResult: (req: SaveMicrobiologyResultRequest) => Promise<Result<SaveMicrobiologyResultResponse>>;
  saveCombineMicrobiologyResult: (req: SaveCombineMicrobiologyResultRequest) => Promise<Result<SaveCombineMicrobiologyResultResponse>>;
  printMicrobiologyResult: (req: PrintMicrobiologyResultRequest) => Promise<Result<PrintMicrobiologyResultResponse>>;
  deleteMicrobiologyResult: (req: DeleteMicrobiologyResultRequest) => Promise<Result<DeleteMicrobiologyResultResponse>>;
  searchPatients: (req: SearchPatientsRequest) => Promise<Result<SearchPatientsResponse>>;
  patientInformation: (req: PatientInformationRequest) => Promise<Result<PatientInformationResponse>>;
  newPatient: (req: NewPatientRequest) => Promise<Result<NewPatientResponse>>;
  savePatient: (req: SavePatientRequest) => Promise<Result<SavePatientResponse>>;
  getPatientForEdit: (req: GetPatientForEditRequest) => Promise<Result<GetPatientForEditResponse>>;
  sendPatientLoginInfo: (req: SendPatientLoginInfoRequest) => Promise<Result<SendPatientLoginInfoResponse>>;
  listDoctorTemplates: (req: ListDoctorTemplatesRequest) => Promise<Result<ListDoctorTemplatesResponse>>;
  getDoctorTemplate: (req: GetDoctorTemplateRequest) => Promise<Result<GetDoctorTemplateResponse>>;
  deleteDoctorTemplate: (req: DeleteDoctorTemplateRequest) => Promise<Result<DeleteDoctorTemplateResponse>>;
  saveDoctorTemplates: (req: SaveDoctorTemplatesRequest) => Promise<Result<SaveDoctorTemplatesResponse>>;
  newDoctorTemplate: (req: NewDoctorTemplateRequest) => Promise<Result<NewDoctorTemplateResponse>>;
  saveSessionInfo: (req: SaveSessionInfoRequest) => Promise<Result<SaveSessionInfoResponse>>;
  getSessionInfo: (req: GetSessionInfoRequest) => Promise<Result<GetSessionInfoResponse>>;
  getDicomJStudies: (req: GetDicomJStudiesRequest) => Promise<Result<GetDicomJStudiesResponse>>;
  getDicomInstances: (req: GetDicomInstancesRequest) => Promise<Result<GetDicomInstancessResponse>>;
  setDoctorDicomJStudyArchiveForProcessing: (req: SetDoctorDicomJStudyArchiveForProcessingRequest) => Promise<Result<SetDicomJStudyArchiveForProcessingResponse>>;
  setPatientDicomJStudyArchiveForProcessing: (req: SetPatientDicomJStudyArchiveForProcessingRequest) => Promise<Result<SetDicomJStudyArchiveForProcessingResponse>>;
  getDoctorDicomJStudyArchiveStatus: (req: GetDoctorDicomJStudyArchiveStatusRequest) => Promise<Result<GetDicomJStudyArchiveStatusResponse>>;
  getPatientDicomJStudyArchiveStatus: (req: GetPatientDicomJStudyArchiveStatusRequest) => Promise<Result<GetDicomJStudyArchiveStatusResponse>>;
  downloadDicomJStudyArchive: (req: DownloadDicomJStudyArchiveRequest) => Promise<Result<DownloadDicomJStudyArchiveResponse>>;
  getPatientImaging: (req: GetPatientImagingRequest) => Promise<Result<GetPatientImagingResponse>>;
  getPromotions: (req: GetPromotionsRequest) => Promise<Result<GetPromotionsResponse>>;
  savePromotionDetail: (req: SavePromotionDetailRequest) => Promise<Result<SavePromotionDetailResponse>>;
  getPromotionDetail: (req: GetPromotionDetailRequest) => Promise<Result<GetPromotionDetailResponse>>;
  checkPromotionDetail: (req: CheckPromotionDetailRequest) => Promise<Result<CheckPromotionDetailResponse>>;
  login: (req: LoginRequest) => Promise<Result<LoginResponse>>;
  loginWithToken: (req: LoginWithTokenRequest) => Promise<Result<LoginResponse>>;
  changeUsernameAndPassword: (req: ChangeUsernameAndPasswordRequest) => Promise<Result<ChangeUsernameAndPasswordResponse>>;
  invalidateAccount: (req: InvalidateAccountRequest) => Promise<Result<InvalidateAccountResponse>>;
  doctorChangePassword: (req: DoctorChangePasswordRequest) => Promise<Result<DoctorChangePasswordResponse>>;
}

export const serverApi = (api: JCoreClient): ServerApiClient => ({
  appointmentWorkScheduler: (req) => api.send<AppointmentWorkSchedulerRequest, AppointmentWorkSchedulerResponse>('AppointmentWorkSchedulerRequest', req),
  appointmentCabinets: (req) => api.send<AppointmentCabinetsRequest, AppointmentCabinetsResponse>('AppointmentCabinetsRequest', req),
  appoCabinets: (req) => api.send<AppoCabinetsRequest>('AppoCabinetsRequest', req),
  appointmentDoctors: (req) => api.send<AppointmentDoctorsRequest, AppointmentDoctorsResponse>('AppointmentDoctorsRequest', req),
  appoDoctors: (req) => api.send<AppoDoctorsRequest>('AppoDoctorsRequest', req),
  appointmentDays: (req) => api.send<AppointmentDaysRequest, AppointmentDaysResponse>('AppointmentDaysRequest', req),
  appoDays: (req) => api.send<AppoDaysRequest>('AppoDaysRequest', req),
  appointmentHours: (req) => api.send<AppointmentHoursRequest, AppointmentHoursResponse>('AppointmentHoursRequest', req),
  appoHours: (req) => api.send<AppoHoursRequest>('AppoHoursRequest', req),
  makeAppointment: (req) => api.send<MakeAppointmentRequest, MakeAppointmentResponse>('MakeAppointmentRequest', req),
  appoMake: (req) => api.send<AppoMakeRequest>('AppoMakeRequest', req),
  appoDelete: (req) => api.send<AppoDeleteRequest>('AppoDeleteRequest', req),
  appoGet: (req) => api.send<AppoGetRequest>('AppoGetRequest', req),
  eventsSend: (req) => api.send<EventsSendMessage>('EventsSendMessage', req),
  eventsSendMessageBr: (req) => api.send<EventsSendMessageBroadcast>('EventsSendMessageBroadcast', req),
  eventsLogin: (req) => api.send<EventsLoginRequest, EventsLoginResponse>('EventsLoginRequest', req),
  eventsLogout: (req) => api.send<EventsLogoutRequest, EventsLogoutResponse>('EventsLogoutRequest', req),
  eventsOnlineUsers: (req) => api.send<EventsOnlineUsersRequest, EventsOnlineUsersResponse>('EventsOnlineUsersRequest', req),
  userMessage: (req) => api.send<UserMessageRequest, UserMessageResponse>('UserMessageRequest', req),
  firstContact: (req) => api.send<FirstContactRequest>('FirstContactRequest', req),
  clientLogin: (req) => api.send<ClientLoginRequest>('ClientLoginRequest', req),
  inventoryEmailReport: (req) => api.send<InventoryEmailReportRequest>('InventoryEmailReportRequest', req),
  identTypes: (req) => api.send<IdentTypesRequest, IdentTypesResponse>('IdentTypesRequest', req),
  locationCountries: (req) => api.send<LocationCountriesRequest, LocationCountriesResponse>('LocationCountriesRequest', req),
  locationStatesCities: (req) => api.send<LocationStatesCitiesRequest, LocationStatesCitiesResponse>('LocationStatesCitiesRequest', req),
  locationQuarters: (req) => api.send<LocationQuartersRequest, LocationQuartersResponse>('LocationQuartersRequest', req),
  getOperationCabinets: (req) => api.send<GetOperationCabinetsRequest, GetOperationCabinetsResponse>('GetOperationCabinetsRequest', req),
  getOperationTypes: (req) => api.send<GetOperationTypesRequest, GetOperationTypesResponse>('GetOperationTypesRequest', req),
  getOperations: (req) => api.send<GetOperationsRequest, GetOperationsResponse>('GetOperationsRequest', req),
  saveOperation: (req) => api.send<SaveOperationRequest, SaveOperationResponse>('SaveOperationRequest', req),
  deleteOperation: (req) => api.send<DeleteOperationRequest, DeleteOperationResponse>('DeleteOperationRequest', req),
  setCurrentUpdateStatus: (req) => api.send<SetCurrentUpdateStatusRequest, SetCurrentUpdateStatusResponse>('SetCurrentUpdateStatusRequest', req),
  currentStatus: (req) => api.send<CurrentStatusRequest, CurrentStatusResponse>('CurrentStatusRequest', req),
  currentVersion: (req) => api.send<CurrentVersionRequest>('CurrentVersionRequest', req),
  versionedResources: (req) => api.send<VersionedResourcesRequest, VersionedResourcesResponse>('VersionedResourcesRequest', req),
  getMicrobiologyMaterials: (req) => api.send<GetMicrobiologyMaterialsRequest, GetMicrobiologyMaterialsResponse>('GetMicrobiologyMaterialsRequest', req),
  getMicrobiologyMicrobes: (req) => api.send<GetMicrobiologyMicrobesRequest, GetMicrobiologyMicrobesResponse>('GetMicrobiologyMicrobesRequest', req),
  getMicrobiologyAntibiogrames: (req) => api.send<GetMicrobiologyAntibiogramesRequest, GetMicrobiologyAntibiogramesResponse>('GetMicrobiologyAntibiogramesRequest', req),
  aiResultReading: (req) => api.send<AiResultReadingRequest, AiResultReadingResponse>('AiResultReadingRequest', req),
  generateCertificate: (req) => api.send<GenerateCertificateRequest, GenerateCertificateResponse>('GenerateCertificateRequest', req),
  getCertificate: (req) => api.send<GetCertificateRequest, GetCertificateResponse>('GetCertificateRequest', req),
  getRsaDocuments: (req) => api.send<GetRsaDocumentsRequest, GetRsaDocumentsResponse>('GetRsaDocumentsRequest', req),
  signRsaDocument: (req) => api.send<SignRsaDocumentRequest, SignRsaDocumentResponse>('SignRsaDocumentRequest', req),
  getRsaDocumentPreview: (req) => api.send<GetRsaDocumentPreviewRequest, GetRsaDocumentPreviewResponse>('GetRsaDocumentPreviewRequest', req),
  deleteCertificate: (req) => api.send<DeleteCertificateRequest, DeleteCertificateResponse>('DeleteCertificateRequest', req),
  signPdf: (req) => api.send<SignPdfRequest, SignPdfResponse>('SignPdfRequest', req),
  checkPdfSigning: (req) => api.send<CheckPdfSigningRequest, CheckPdfSigningResponse>('CheckPdfSigningRequest', req),
  digitalSignPdf: (req) => api.send<DigitalSignPdfRequest, DigitalSignPdfResponse>('DigitalSignPdfRequest', req),
  patientAppointmentDelay: (req) => api.send<PatientAppointmentDelayRequest, PatientAppointmentDelayResponse>('PatientAppointmentDelayRequest', req),
  patientBillingInformation: (req) => api.send<PatientBillingInformationRequest, PatientBillingInformationResponse>('PatientBillingInformationRequest', req),
  patientBillingInvoiceDownload: (req) => api.send<PatientBillingInvoiceDownloadRequest, PatientBillingInvoiceDownloadResponse>('PatientBillingInvoiceDownloadRequest', req),
  saveFirebasePersonalToken: (req) => api.send<SaveFirebasePersonalTokenRequest, SaveFirebasePersonalTokenResponse>('SaveFirebasePersonalTokenRequest', req),
  patientNotifications: (req) => api.send<PatientNotificationsRequest>('PatientNotificationsRequest', req),
  consultGetJRequests: (req) => api.send<ConsultGetJRequestsRequest, ConsultGetJRequestsResponse>('ConsultGetJRequestsRequest', req),
  consultGetCabinetsDoctors: (req) => api.send<ConsultGetCabinetsDoctorsRequest, ConsultGetCabinetsDoctorsResponse>('ConsultGetCabinetsDoctorsRequest', req),
  consultGetCabinetAppointments: (req) => api.send<ConsultGetCabinetAppointmentsRequest, ConsultGetCabinetAppointmentsResponse>('ConsultGetCabinetAppointmentsRequest', req),
  consultGetPriceList: (req) => api.send<ConsultGetPriceListRequest, ConsultGetPriceListResponse>('ConsultGetPriceListRequest', req),
  consultSaveJRequest: (req) => api.send<ConsultSaveJRequestRequest, ConsultSaveJRequestResponse>('ConsultSaveJRequestRequest', req),
  consultDeleteJRequest: (req) => api.send<ConsultDeleteJRequestRequest, ConsultDeleteJRequestResponse>('ConsultDeleteJRequestRequest', req),
  consultSaveAttachedResult: (req) => api.send<ConsultSaveAttachedResultRequest, ConsultSaveAttachedResultResponse>('ConsultSaveAttachedResultRequest', req),
  consultDeleteAttachedResult: (req) => api.send<ConsultDeleteAttachedResultRequest, ConsultDeleteAttachedResultResponse>('ConsultDeleteAttachedResultRequest', req),
  patientRegister: (req) => api.send<PatientRegisterRequest, PatientRegisterResponse>('PatientRegisterRequest', req),
  validatePatientInformation: (req) => api.send<ValidatePatientInformationRequest, ValidatePatientInformationResponse>('ValidatePatientInformationRequest', req),
  patientPersonalData: (req) => api.send<PatientPersonalDataRequest, PatientPersonalDataResponse>('PatientPersonalDataRequest', req),
  getPatientSignedDocument: (req) => api.send<GetPatientSignedDocumentRequest, GetPatientSignedDocumentResponse>('GetPatientSignedDocumentRequest', req),
  getPatientSignedDocumentsList: (req) => api.send<GetPatientSignedDocumentsListRequest, GetPatientSignedDocumentsListResponse>('GetPatientSignedDocumentsListRequest', req),
  patientOwnInformation: (req) => api.send<PatientOwnInformationRequest, PatientOwnInformationResponse>('PatientOwnInformationRequest', req),
  patientPrescriptions: (req) => api.send<PatientPrescriptionsRequest, PatientPrescriptionsResponse>('PatientPrescriptionsRequest', req),
  patientPrescriptionPdf: (req) => api.send<PatientPrescriptionPdfRequest, PatientPrescriptionPdfResponse>('PatientPrescriptionPdfRequest', req),
  getAd: (req) => api.send<GetAdRequest, GetAdResponse>('GetAdRequest', req),
  markAdAsDisplayed: (req) => api.send<MarkAdAsDisplayedRequest, MarkAdAsDisplayedResponse>('MarkAdAsDisplayedRequest', req),
  markAdAsClicked: (req) => api.send<MarkAdAsClickedRequest, MarkAdAsClickedResponse>('MarkAdAsClickedRequest', req),
  patientRestorePassword: (req) => api.send<PatientRestorePasswordRequest, PatientRestorePasswordResponse>('PatientRestorePasswordRequest', req),
  patientLabResults: (req) => api.send<PatientLabResultsRequest, PatientLabResultsResponse>('PatientLabResultsRequest', req),
  patientResult: (req) => api.send<PatientResultRequest, PatientResultResponse>('PatientResultRequest', req),
  publicPatientResult: (req) => api.send<PublicPatientResultRequest, PublicPatientResultResponse>('PublicPatientResultRequest', req),
  dynamicPatientDossieGet: (req) => api.send<DynamicPatientDossieGetRequest, DynamicPatientDossieGetResponse>('DynamicPatientDossieGetRequest', req),
  mergePdfs: (req) => api.send<MergePdfsRequest, MergePdfsResponse>('MergePdfsRequest', req),
  getPatientInformedConsentPdf: (req) => api.send<GetPatientInformedConsentPdfRequest, GetPatientInformedConsentPdfResponse>('GetPatientInformedConsentPdfRequest', req),
  getViberQr: (req) => api.send<GetViberQrRequest, GetViberQrResponse>('GetViberQrRequest', req),
  getViberPublicQr: (req) => api.send<GetViberPublicQrRequest, GetViberPubliQrResponse>('GetViberPublicQrRequest', req),
  patientObligations: (req) => api.send<PatientObligationsRequest, PatientObligationsResponse>('PatientObligationsRequest', req),
  patientCarts: (req) => api.send<PatientCartsRequest, PatientCartsResponse>('PatientCartsRequest', req),
  patientCreateCart: (req) => api.send<PatientCreateCartRequest, PatientCreateCartResponse>('PatientCreateCartRequest', req),
  patientCheckCartStatus: (req) => api.send<PatientCheckCartStatusRequest, PatientCheckCartStatusResponse>('PatientCheckCartStatusRequest', req),
  patientStornoCart: (req) => api.send<PatientStornoCartRequest, PatientStornoCartResponse>('PatientStornoCartRequest', req),
  processCartPayment: (req) => api.send<ProcessCartPaymentRequest, ProcessCartPaymentResponse>('ProcessCartPaymentRequest', req),
  patientVisits: (req) => api.send<PatientVisitsRequest, PatientVisitsResponse>('PatientVisitsRequest', req),
  cancellationAppointment: (req) => api.send<CancellationAppointmentRequest>('CancellationAppointmentRequest', req),
  jabchoSettings: (req) => api.send<JabchoSettingsRequest, JabchoSettingsResponse>('JabchoSettingsRequest', req),
  jabchoLogin: (req) => api.send<JabchoLoginRequest, JabchoLoginResponse>('JabchoLoginRequest', req),
  jabchoLogout: (req) => api.send<JabchoLogoutRequest, JabchoLogoutResponse>('JabchoLogoutRequest', req),
  notificationPatients: (req) => api.send<NotificationPatientsRequest, NotificationPatientsResponse>('NotificationPatientsRequest', req),
  sendNotification: (req) => api.send<SendNotificationRequest, SendNotificationResponse>('SendNotificationRequest', req),
  patientQuestionsDoctors: (req) => api.send<PatientQuestionsDoctorsRequest, PatientQuestionsDoctorsResponse>('PatientQuestionsDoctorsRequest', req),
  patientAskQuestion: (req) => api.send<PatientAskQuestionRequest, PatientAskQuestionResponse>('PatientAskQuestionRequest', req),
  patientQuestionsList: (req) => api.send<PatientQuestionsListRequest, PatientQuestionsListResponse>('PatientQuestionsListRequest', req),
  displayAppointment: (req) => api.send<DisplayAppointmentRequest, DisplayAppointmentResponse>('DisplayAppointmentRequest', req),
  attachedResultsList: (req) => api.send<AttachedResultsListRequest, AttachedResultsListResponse>('AttachedResultsListRequest', req),
  saveAttachedResult: (req) => api.send<SaveAttachedResultRequest, SaveAttachedResultResponse>('SaveAttachedResultRequest', req),
  getAttachedResult: (req) => api.send<GetAttachedResultRequest, GetAttachedResultResponse>('GetAttachedResultRequest', req),
  newAttachedResult: (req) => api.send<NewAttachedResultRequest, NewAttachedResultResponse>('NewAttachedResultRequest', req),
  deleteAttachedResult: (req) => api.send<DeleteAttachedResultRequest, DeleteAttachedResultResponse>('DeleteAttachedResultRequest', req),
  cytologyNomenclatures: (req) => api.send<CytologyNomenclaturesRequest, CytologyNomenclaturesResponse>('CytologyNomenclaturesRequest', req),
  cytologyResultList: (req) => api.send<CytologyResultListRequest, CytologyResultListResponse>('CytologyResultListRequest', req),
  getCytologyResult: (req) => api.send<GetCytologyResultRequest, GetCytologyResultResponse>('GetCytologyResultRequest', req),
  newCytologyResult: (req) => api.send<NewCytologyResultRequest, NewCytologyResultResponse>('NewCytologyResultRequest', req),
  saveCytologyResult: (req) => api.send<SaveCytologyResultRequest, SaveCytologyResultResponse>('SaveCytologyResultRequest', req),
  printCytologyResult: (req) => api.send<PrintCytologyResultRequest, PrintCytologyResultResponse>('PrintCytologyResultRequest', req),
  deleteCytologyResult: (req) => api.send<DeleteCytologyResultRequest, DeleteCytologyResultResponse>('DeleteCytologyResultRequest', req),
  doctorCatalog: (req) => api.send<DoctorCatalogRequest, DoctorCatalogResponse>('DoctorCatalogRequest', req),
  getDoctorJRequests: (req) => api.send<GetDoctorJRequestsRequest, GetDoctorJRequestsResponse>('GetDoctorJRequestsRequest', req),
  getJRequest: (req) => api.send<GetJRequestRequest, GetJRequestResponse>('GetJRequestRequest', req),
  senderDoctorResultList: (req) => api.send<SenderDoctorResultListRequest, SenderDoctorResultListResponse>('SenderDoctorResultListRequest', req),
  senderDoctorResultView: (req) => api.send<SenderDoctorResultViewRequest, SenderDoctorResultsViewResponse>('SenderDoctorResultViewRequest', req),
  doctorSchedule: (req) => api.send<DoctorScheduleRequest, DoctorScheduleResponse>('DoctorScheduleRequest', req),
  doctorAppoMake: (req) => api.send<DoctorAppoMakeRequest>('DoctorAppoMakeRequest', req),
  getSignableDocumentsList: (req) => api.send<GetSignableDocumentsListRequest, GetSignableDocumentsListResponse>('GetSignableDocumentsListRequest', req),
  getNextDocumentToSign: (req) => api.send<GetNextDocumentToSignRequest, GetNextDocumentToSignResponse>('GetNextDocumentToSignRequest', req),
  getSignableDocument: (req) => api.send<GetSignableDocumentRequest, GetSignableDocumentResponse>('GetSignableDocumentRequest', req),
  saveSignableDocument: (req) => api.send<SaveSignableDocumentRequest, SaveSignableDocumentResponse>('SaveSignableDocumentRequest', req),
  setSignableDocumentStatus: (req) => api.send<SetSignableDocumentStatusRequest, SetSignableDocumentStatusResponse>('SetSignableDocumentStatusRequest', req),
  getDoctorSignature: (req) => api.send<GetDoctorSignatureRequest, GetDoctorSignatureResponse>('GetDoctorSignatureRequest', req),
  jRequestList: (req) => api.send<JRequestListRequest, JRequestListResponse>('JRequestListRequest', req),
  newJRequest: (req) => api.send<NewJRequestRequest, NewJRequestResponse>('NewJRequestRequest', req),
  deleteJRequest: (req) => api.send<DeleteJRequestRequest, DeleteJRequestResponse>('DeleteJRequestRequest', req),
  jRequestPriceList: (req) => api.send<JRequestPriceListRequest, JRequestPriceListResponse>('JRequestPriceListRequest', req),
  jOrdersInfo: (req) => api.send<JOrdersInfoRequest, JOrdersInfoResponse>('JOrdersInfoRequest', req),
  saveJRequest: (req) => api.send<SaveJRequestRequest, SaveJRequestResponse>('SaveJRequestRequest', req),
  jRequestReport: (req) => api.send<JRequestReportRequest, JRequestReportResponse>('JRequestReportRequest', req),
  generateQrCode: (req) => api.send<GenerateQrCodeRequest, GenerateQrCodeResponse>('GenerateQrCodeRequest', req),
  doctorAgentMeetingsCatalog: (req) => api.send<DoctorAgentMeetingsCatalogRequest, DoctorAgentMeetingsCatalogResponse>('DoctorAgentMeetingsCatalogRequest', req),
  getMedicalAgentNewEdit: (req) => api.send<GetMedicalAgentNewEditRequest, GetMedicalAgentNewEditResponse>('GetMedicalAgentNewEditRequest', req),
  saveMedicalAgent: (req) => api.send<SaveMedicalAgentRequest, SaveMedicalAgentResponse>('SaveMedicalAgentRequest', req),
  deleteDoctorAgentMeeting: (req) => api.send<DeleteDoctorAgentMeetingRequest, DeleteDoctorAgentMeetingResponse>('DeleteDoctorAgentMeetingRequest', req),
  microbiologyResultList: (req) => api.send<MicrobiologyResultListRequest, MicrobiologyResultListResponse>('MicrobiologyResultListRequest', req),
  getMicrobiologyResult: (req) => api.send<GetMicrobiologyResultRequest, GetMicrobiologyResultResponse>('GetMicrobiologyResultRequest', req),
  newMicrobiologyResult: (req) => api.send<NewMicrobiologyResultRequest, NewMicrobiologyResultResponse>('NewMicrobiologyResultRequest', req),
  saveMicrobiologyResult: (req) => api.send<SaveMicrobiologyResultRequest, SaveMicrobiologyResultResponse>('SaveMicrobiologyResultRequest', req),
  saveCombineMicrobiologyResult: (req) => api.send<SaveCombineMicrobiologyResultRequest, SaveCombineMicrobiologyResultResponse>('SaveCombineMicrobiologyResultRequest', req),
  printMicrobiologyResult: (req) => api.send<PrintMicrobiologyResultRequest, PrintMicrobiologyResultResponse>('PrintMicrobiologyResultRequest', req),
  deleteMicrobiologyResult: (req) => api.send<DeleteMicrobiologyResultRequest, DeleteMicrobiologyResultResponse>('DeleteMicrobiologyResultRequest', req),
  searchPatients: (req) => api.send<SearchPatientsRequest, SearchPatientsResponse>('SearchPatientsRequest', req),
  patientInformation: (req) => api.send<PatientInformationRequest, PatientInformationResponse>('PatientInformationRequest', req),
  newPatient: (req) => api.send<NewPatientRequest, NewPatientResponse>('NewPatientRequest', req),
  savePatient: (req) => api.send<SavePatientRequest, SavePatientResponse>('SavePatientRequest', req),
  getPatientForEdit: (req) => api.send<GetPatientForEditRequest, GetPatientForEditResponse>('GetPatientForEditRequest', req),
  sendPatientLoginInfo: (req) => api.send<SendPatientLoginInfoRequest, SendPatientLoginInfoResponse>('SendPatientLoginInfoRequest', req),
  listDoctorTemplates: (req) => api.send<ListDoctorTemplatesRequest, ListDoctorTemplatesResponse>('ListDoctorTemplatesRequest', req),
  getDoctorTemplate: (req) => api.send<GetDoctorTemplateRequest, GetDoctorTemplateResponse>('GetDoctorTemplateRequest', req),
  deleteDoctorTemplate: (req) => api.send<DeleteDoctorTemplateRequest, DeleteDoctorTemplateResponse>('DeleteDoctorTemplateRequest', req),
  saveDoctorTemplates: (req) => api.send<SaveDoctorTemplatesRequest, SaveDoctorTemplatesResponse>('SaveDoctorTemplatesRequest', req),
  newDoctorTemplate: (req) => api.send<NewDoctorTemplateRequest, NewDoctorTemplateResponse>('NewDoctorTemplateRequest', req),
  saveSessionInfo: (req) => api.send<SaveSessionInfoRequest, SaveSessionInfoResponse>('SaveSessionInfoRequest', req),
  getSessionInfo: (req) => api.send<GetSessionInfoRequest, GetSessionInfoResponse>('GetSessionInfoRequest', req),
  getDicomJStudies: (req) => api.send<GetDicomJStudiesRequest, GetDicomJStudiesResponse>('GetDicomJStudiesRequest', req),
  getDicomInstances: (req) => api.send<GetDicomInstancesRequest, GetDicomInstancessResponse>('GetDicomInstancesRequest', req),
  setDoctorDicomJStudyArchiveForProcessing: (req) => api.send<SetDoctorDicomJStudyArchiveForProcessingRequest, SetDicomJStudyArchiveForProcessingResponse>('SetDoctorDicomJStudyArchiveForProcessingRequest', req),
  setPatientDicomJStudyArchiveForProcessing: (req) => api.send<SetPatientDicomJStudyArchiveForProcessingRequest, SetDicomJStudyArchiveForProcessingResponse>('SetPatientDicomJStudyArchiveForProcessingRequest', req),
  getDoctorDicomJStudyArchiveStatus: (req) => api.send<GetDoctorDicomJStudyArchiveStatusRequest, GetDicomJStudyArchiveStatusResponse>('GetDoctorDicomJStudyArchiveStatusRequest', req),
  getPatientDicomJStudyArchiveStatus: (req) => api.send<GetPatientDicomJStudyArchiveStatusRequest, GetDicomJStudyArchiveStatusResponse>('GetPatientDicomJStudyArchiveStatusRequest', req),
  downloadDicomJStudyArchive: (req) => api.send<DownloadDicomJStudyArchiveRequest, DownloadDicomJStudyArchiveResponse>('DownloadDicomJStudyArchiveRequest', req),
  getPatientImaging: (req) => api.send<GetPatientImagingRequest, GetPatientImagingResponse>('GetPatientImagingRequest', req),
  getPromotions: (req) => api.send<GetPromotionsRequest, GetPromotionsResponse>('GetPromotionsRequest', req),
  savePromotionDetail: (req) => api.send<SavePromotionDetailRequest, SavePromotionDetailResponse>('SavePromotionDetailRequest', req),
  getPromotionDetail: (req) => api.send<GetPromotionDetailRequest, GetPromotionDetailResponse>('GetPromotionDetailRequest', req),
  checkPromotionDetail: (req) => api.send<CheckPromotionDetailRequest, CheckPromotionDetailResponse>('CheckPromotionDetailRequest', req),
  login: (req) => api.send<LoginRequest, LoginResponse>('LoginRequest', req),
  loginWithToken: (req) => api.send<LoginWithTokenRequest, LoginResponse>('LoginWithTokenRequest', req),
  changeUsernameAndPassword: (req) => api.send<ChangeUsernameAndPasswordRequest, ChangeUsernameAndPasswordResponse>('ChangeUsernameAndPasswordRequest', req),
  invalidateAccount: (req) => api.send<InvalidateAccountRequest, InvalidateAccountResponse>('InvalidateAccountRequest', req),
  doctorChangePassword: (req) => api.send<DoctorChangePasswordRequest, DoctorChangePasswordResponse>('DoctorChangePasswordRequest', req),
});

export const serverValidations = {
    appointmentWorkSchedulerRequest: {
        fromDate: [],
        toDate: [],
        cabinetID: [],
        doctorID: [],
        isPrivate: [],
    },
    appointmentCabinetsRequest: {
    },
    appoCabinetsRequest: {
    },
    appointmentDoctorsRequest: {
        cabinetID: [],
    },
    appoDoctorsRequest: {
        cabinetID: [],
    },
    appointmentDaysRequest: {
        cabinetID: [],
        doctorID: [],
    },
    appoDaysRequest: {
        cabinetID: [],
        doctorID: [],
    },
    appointmentHoursRequest: {
        cabinetID: [],
        doctorID: [],
        date: [],
    },
    appoHoursRequest: {
        cabinetID: [],
        doctorID: [],
        date: [],
    },
    makeAppointmentRequest: {
        item: [],
    },
    appointmentDto: {
        cabinetID: [ validations.required('Моля, изберете кабинет.') ],
        doctorID: [ validations.required('Моля, изберете лекар.') ],
        dateTime: [ validations.required('Моля, въведете дата и час.') ],
        patientName: [ validations.required('Моля, въведете име.') ],
        patientMobile: [ validations.required('Моля, въведете телефон.'), validations.phoneNumber('Моля, въведете правилен телефон.') ],
        patientIdentNumber: [ validations.patientIdentNumber('Моля, въведете правилно ЕГН/ЛНЧ.') ],
        note: [ validations.maxLength(255, 'Полето е прекалено дълго.') ],
        address: [ validations.maxLength(350, 'Полето е прекалено дълго.') ],
        patientID: [],
        patientEmail: [],
    },
    appoMakeRequest: {
        appoID: [],
        cabinetID: [],
        doctorID: [],
        dateTime: [],
        patientName: [],
        patientMobile: [],
        patientIdentNumber: [],
        note: [],
        patientID: [],
        patientEmail: [],
        isNzok: [],
        isFinFund: [],
        insurerId: [],
        insurerStatus: [],
        insurerName: [],
        reasonId: [],
        reasonName: [],
        paymentId: [],
        paymentName: [],
    },
    appoDeleteRequest: {
        appoID: [],
    },
    appoGetRequest: {
        appoIDs: [],
        cabinetID: [],
        doctorID: [],
        fromDate: [],
        toDate: [],
    },
    eventsSendMessage: {
        senderPollSessionID: [],
        pollSessionID: [],
        pollMessageTypeName: [],
        pollMessageContain: [],
    },
    eventsSendMessageBroadcast: {
        senderPollSessionID: [],
        pollSessionID: [],
        pollMessageTypeName: [],
        pollMessageContain: [],
    },
    eventsLoginRequest: {
        login: [],
    },
    eventsLogin: {
        computerName: [],
        ipInfo: [],
        jversion: [],
        loginDt: [],
        outloginDt: [],
        sessionID: [],
        userid: [],
        userDisplayInfo: [],
    },
    eventsLogoutRequest: {
        sessionID: [],
    },
    eventsOnlineUsersRequest: {
    },
    userMessageRequest: {
        message: [],
    },
    firstContactRequest: {
        ipAddress: [],
        computerName: [],
        joystickVersion: [],
    },
    clientLoginRequest: {
        serverTime: [],
        computerName: [],
        joystickVersion: [],
        userName: [],
        userPassword: [],
        ipAddress: [],
        subscriptionId: [],
    },
    inventoryEmailReportRequest: {
    },
    identTypesRequest: {
    },
    locationCountriesRequest: {
    },
    locationStatesCitiesRequest: {
    },
    locationQuartersRequest: {
        cityID: [],
    },
    getOperationCabinetsRequest: {
    },
    getOperationTypesRequest: {
    },
    getOperationsRequest: {
        workDate: [],
        grOperationID: [],
    },
    saveOperationRequest: {
        operation: [],
    },
    grOperationDto: {
        grOperationID: [],
        grCabinetID: [  ],
        operatorDoctorName: [],
        grStartDateTime: [  ],
        grEndDateTime: [  ],
        grOperationTypeID: [  ],
        grDescription: [],
        grOperationPatientIdentNumber: [],
        grOperationPatientName: [ validations.required('Липсва име на пациента!'), validations.minLength(4, 'Името на пациента трябва да е повече от 3 символа!') ],
        grOperationPatientMobile: [],
    },
    deleteOperationRequest: {
        grOperationID: [],
    },
    setCurrentUpdateStatusRequest: {
        isUpdating: [],
    },
    currentStatusRequest: {
    },
    currentVersionRequest: {
    },
    versionedResourcesRequest: {
        forceUpdate: [],
        aCodesVersion: [],
        userPrivilegesVersion: [],
    },
    getMicrobiologyMaterialsRequest: {
        referenceDate: [],
        currentACode: [],
    },
    getMicrobiologyMicrobesRequest: {
        referenceDate: [],
        currentACode: [],
    },
    getMicrobiologyAntibiogramesRequest: {
        referenceDate: [],
    },
    aiResultReadingRequest: {
        uniqueRecordPrefix: [],
    },
    generateCertificateRequest: {
        pin: [ validations.required('PIN трябва да е поне 4 символа'), validations.minLength(4, 'PIN трябва да е поне 4 символа') ],
    },
    getCertificateRequest: {
    },
    getRsaDocumentsRequest: {
    },
    signRsaDocumentRequest: {
        signDocID: [],
        pin: [],
    },
    getRsaDocumentPreviewRequest: {
        signDocID: [],
    },
    deleteCertificateRequest: {
    },
    signPdfRequest: {
        pdfBase64: [],
        certificatePfxBase64: [],
        certificatePassword: [],
    },
    checkPdfSigningRequest: {
        pdfFilePath: [],
        pdfFileBase64: [],
    },
    digitalSignPdfRequest: {
        type: [],
        pdfToSignPath: [],
        signedPdfPath: [],
        privateKeyPath: [],
    },
    patientAppointmentDelayRequest: {
    },
    patientBillingInformationRequest: {
    },
    patientBillingInvoiceDownloadRequest: {
        fDocID: [],
    },
    saveFirebasePersonalTokenRequest: {
        personalToken: [],
    },
    patientNotificationsRequest: {
    },
    consultGetJRequestsRequest: {
    },
    consultGetCabinetsDoctorsRequest: {
    },
    consultGetCabinetAppointmentsRequest: {
        doctorID: [],
    },
    consultGetPriceListRequest: {
        cabinetID: [],
    },
    consultSaveJRequestRequest: {
        cabinetID: [ validations.required('Липсва кабинет!') ],
        doctorID: [ validations.required('Липсва лекар!') ],
        workAppMetaID: [],
        appointmentFromDateTime: [],
        patientEmail: [ validations.required('Моля, въведете имейл.') ],
        jRequestNote: [],
        serviceIDs: [ validations.notEmptyCollection('Трябва да има поне една избрана услуга!') ],
    },
    consultDeleteJRequestRequest: {
        jRequestID: [  ],
    },
    consultSaveAttachedResultRequest: {
        pdfFileContent: [ validations.required('Pdf файлът е задължителен.') ],
        patientRecShortName: [ validations.required('Липсва описание на Pdf файла.') ],
        jRequestID: [  ],
    },
    consultDeleteAttachedResultRequest: {
        patientRecID: [  ],
    },
    patientRegisterRequest: {
        acceptToS: [],
        patient: [],
    },
    patientRegisterDto: {
        identTypeID: [],
        patientIdentNumber: [ validations.required('Моля, въведете ЕГН/ЛНЧ !'), validations.maxLength(13, 'ЕГН/ЛНЧ трябва да е до 13 символа !') ],
        patientPersonalDocNumber: [ validations.maxLength(20, 'ЛК: № трябва да е до 20 символа !') ],
        patientFirstName: [ validations.required('Моля, въведете име !'), validations.maxLength(70, 'Името трябва да е до 70 символа !'), validations.regex(/^[А-я \-]+$/g, 'Името може да съдържа само буквите от А до Я, празно място и тире !') ],
        patientSecondName: [ validations.maxLength(70, 'Презимето трябва да е до 70 символа !'), validations.regex(/^[А-я \-]+$/g, 'Презимето може да съдържа само буквите от А до Я, празно място и тире !') ],
        patientLastName: [ validations.required('Моля, въведете фамилия !'), validations.maxLength(70, 'Фамилията трябва да е до 70 символа !'), validations.regex(/^[А-я \-]+$/g, 'Фамилията може да съдържа само буквите от А до Я, празно място и тире !') ],
        patientNameEN: [ validations.maxLength(200, 'Пълно име на английски трябва да е до 200 символа !'), validations.regex(/^[A-z \-]+$/g, 'Пълното име на английски може да съдържа само буквите от A до Z, празно място и тире !') ],
        patientBirthDay: [],
        patientSex: [],
        patientState: [ validations.maxLength(70, 'Област трябва да е до 70 символа !') ],
        patientCity: [ validations.maxLength(70, 'Град трябва да е до 70 символа !') ],
        patientAddress: [ validations.maxLength(250, 'Адрес на регистрация трябва да е до 250 символа !') ],
        patientMobile: [ validations.required('Моля, въведете телефон !'), validations.phoneNumber('Моля, въведете правилен телефон !'), validations.maxLength(45, 'Телефон трябва да е до 45 символа !') ],
        patientEmail: [ validations.required('Моля, въведете имейл !'), validations.email('Моля, въведете правилен имейл адрес !'), validations.maxLength(120, 'Имейл трябва да е до 120 символа !') ],
    },
    validatePatientInformationRequest: {
        item: [],
    },
    newPatientDto: {
        patientID: [],
        patientEmail: [ validations.email('Моля, въведете правилен имейл адрес !'), validations.maxLength(120, 'Имейл трябва да е до 120 символа !') ],
        patientMobile: [ validations.required('Моля, въведете телефон !'), validations.phoneNumber('Моля, въведете правилен телефон !'), validations.maxLength(45, 'Телефон трябва да е до 45 символа !') ],
        identTypeID: [],
        patientIdentNumber: [ validations.maxLength(13, 'ЕГН/ЛНЧ трябва да е до 13 символа !') ],
        patientFirstName: [ validations.required('Моля, въведете име !'), validations.maxLength(70, 'Името трябва да е до 70 символа !') ],
        patientSecondName: [ validations.maxLength(70, 'Презимето трябва да е до 70 символа !') ],
        patientLastName: [ validations.required('Моля, въведете фамилия !'), validations.maxLength(70, 'Фамилията трябва да е до 70 символа !') ],
        patientBirthDay: [ validations.required('Моля, въведете дата на раждане.') ],
        patientSex: [ validations.required('Моля, въведете пол.') ],
        patientNameEN: [ validations.maxLength(200, 'Пълно име на английски трябва да е до 200 символа !'), validations.regex(/^[A-z \-]+$/g, 'Пълното име на английски може да съдържа само буквите от A до Z, празно място и тире !') ],
        patientPersonalDocNumber: [ validations.maxLength(20, 'ЛК: № трябва да е до 20 символа !') ],
    },
    patientPersonalDataRequest: {
    },
    getPatientSignedDocumentRequest: {
        signedDocument: [],
    },
    patientSignedDocumentDto: {
        signDocID: [],
        requestDatetime: [],
        signedDatetime: [],
        docName: [],
        signedFilePathID: [],
        signDocTypeName: [],
    },
    getPatientSignedDocumentsListRequest: {
    },
    patientOwnInformationRequest: {
    },
    patientPrescriptionsRequest: {
    },
    patientPrescriptionPdfRequest: {
        dcDyndocID: [],
    },
    getAdRequest: {
        position: [],
        uniqueRecordPrefix: [],
    },
    markAdAsDisplayedRequest: {
        uniqueRecordPrefix: [],
        acodeID: [],
    },
    markAdAsClickedRequest: {
        uniqueRecordPrefix: [],
        acodeID: [],
    },
    patientRestorePasswordRequest: {
        patient: [],
    },
    patientRestorePasswordDto: {
        patientFirstName: [ validations.required('Моля, въведете име.') ],
        patientSecondName: [],
        patientLastName: [ validations.required('Моля, въведете фамилия.') ],
        patientSex: [  ],
        patientBirthDay: [ validations.required('Моля, въведете дата на раждане.') ],
        patientMobile: [ validations.maxLength(45, 'Телефонния номер трябва да е до 45 символа !') ],
        patientEmail: [ validations.maxLength(120, 'Имейла трябва да е до 120 символа !') ],
    },
    patientLabResultsRequest: {
    },
    patientResultRequest: {
        uniqueRecordPrefix: [],
        documentLanguageType: [],
    },
    publicPatientResultRequest: {
        qrID: [ validations.required('Липсва QrID') ],
    },
    dynamicPatientDossieGetRequest: {
        departmentPationID: [],
    },
    mergePdfsRequest: {
        pdfsBase64: [],
    },
    getPatientInformedConsentPdfRequest: {
        header: [],
        body: [],
        footer: [],
        model: [],
    },
    patientInformedConsent: {
        representativeType: [],
        representativeNames: [],
        representativeEGN: [],
        patientNames: [],
        patientContacts: [],
        issuedDate: [],
        additionalInfo: [],
        currentDayServices: [],
        patientIdentNumber: [],
        thirdPersonInfo: [],
        declaratorMobileNumber: [],
        locationName: [],
        hospitalName: [],
        hospitalCity: [],
    },
    getViberQrRequest: {
    },
    getViberPublicQrRequest: {
        identification: [],
    },
    patientObligationsRequest: {
        obligationKeys: [],
    },
    patientCartsRequest: {
        orderIDs: [],
    },
    patientCreateCartRequest: {
        obligations: [],
    },
    patientCreateCartItem: {
        obligationKey: [],
        forPayActual: [],
    },
    patientCheckCartStatusRequest: {
        cartID: [],
    },
    patientStornoCartRequest: {
        cartID: [],
    },
    processCartPaymentRequest: {
        cartID: [],
        responseData: [],
    },
    patientVisitsRequest: {
        getPastYearVisits: [],
    },
    cancellationAppointmentRequest: {
        wAppointmentPatientID: [],
    },
    jabchoSettingsRequest: {
        loginID: [],
    },
    jabchoLoginRequest: {
        oneSignalDeviceID: [],
    },
    jabchoLogoutRequest: {
        loginID: [],
    },
    notificationPatientsRequest: {
    },
    sendNotificationRequest: {
        content: [ validations.required('Моля, въвдете съдържание.') ],
        patientIDs: [],
    },
    patientQuestionsDoctorsRequest: {
    },
    patientAskQuestionRequest: {
        doctorID: [],
        content: [],
    },
    patientQuestionsListRequest: {
    },
    displayAppointmentRequest: {
    },
    attachedResultsListRequest: {
        fromDate: [],
        patientIdentNumberBeginsWith: [ validations.regex(/\d+/g, 'ЕГН-то може да съдържа само цифри.') ],
        patientFirstNameBeginsWith: [],
        patientSecondNameBeginsWith: [],
        patientLastNameBeginsWith: [],
        docID: [],
        toDate: [],
    },
    saveAttachedResultRequest: {
        item: [],
    },
    attachedResultDto: {
        patientRecDateTime: [],
        pdfFileContent: [],
        patientRecShortName: [],
        docID: [],
        patientRecStatus: [],
        patientRecordID: [],
        patientID: [],
    },
    getAttachedResultRequest: {
        patientRecID: [],
        includeFileContents: [],
    },
    newAttachedResultRequest: {
    },
    deleteAttachedResultRequest: {
        patientRecID: [],
    },
    cytologyNomenclaturesRequest: {
    },
    cytologyResultListRequest: {
        fromDate: [],
        toDate: [],
        patientIdentNumberBeginsWith: [ validations.regex(/\d+/g, 'ЕГН-то може да съдържа само цифри.') ],
        docID: [],
    },
    getCytologyResultRequest: {
        patientRecordID: [],
    },
    newCytologyResultRequest: {
        jRequestID: [],
    },
    saveCytologyResultRequest: {
        item: [],
    },
    cytologyResultDto: {
        patientRecID: [],
        patientID: [],
        cytoDoctorID: [],
        sendLabDateTime: [ validations.required('Моля, въведете дата на изследването.') ],
        resultNum: [ validations.required('Моля, въведете номер на изследването.') ],
        testMethod: [ validations.required('Моля, изберете начин на изследване.') ],
        groupPapanikolauPorcio: [ validations.required('Моля, изберете ГРУПА ПО ПАПАНИКОЛАУ - Порцио.') ],
        groupPapanikolauCervikalenKanal: [ validations.required('Моля, изберете ГРУПА ПО ПАПАНИКОЛАУ - Цервикален канал.') ],
        groupPapanikolau: [ validations.required('Моля, изберете ГРУПА ПО ПАПАНИКОЛАУ.') ],
        normalCell: [],
        cellChangeWithList: [],
        pilList: [],
        cellChangeGlandAdenList: [],
        cellChangeGland: [],
        otherTumor: [],
        hormoneValue: [],
        groupASatisfactory: [],
        adekvatnostB: [],
        adekvatnostV: [],
        adekvatnostG: [],
        conclusion: [],
        resultDate: [ validations.required('Моля, въведете дата на отговор.') ],
        nextExamList: [],
        recommendationsList: [],
        isHasValue: [],
        jRequestID: [],
        clinicalInfoData: [],
    },
    printCytologyResultRequest: {
        patientRecordID: [],
    },
    deleteCytologyResultRequest: {
        patientRecordID: [],
    },
    doctorCatalogRequest: {
        doctorID: [],
        doctorUIN: [],
        doctorFirstName: [],
        doctorSecondName: [],
        doctorLastName: [],
        doctorCategoryID: [],
    },
    getDoctorJRequestsRequest: {
        fromDate: [],
        toDate: [],
        barcode: [],
        patientFirstNameBeginsWith: [],
        patientSecondNameBeginsWith: [],
        patientLastNameBeginsWith: [],
        patientRecordFilter: [],
    },
    getJRequestRequest: {
        jRequestID: [],
    },
    senderDoctorResultListRequest: {
        fromDate: [],
        toDate: [],
        patientIdentNumber: [],
        patientLastName: [],
        patientFirstName: [],
    },
    senderDoctorResultViewRequest: {
        patientRecID: [],
        patientID: [],
    },
    doctorScheduleRequest: {
        fromDate: [],
        toDate: [],
        doctorID: [],
        cabinetID: [],
        showAll: [],
    },
    doctorAppoMakeRequest: {
        appoID: [],
        cabinetID: [],
        doctorID: [],
        dateTime: [],
        patientName: [],
        patientMobile: [],
        patientIdentNumber: [],
        note: [],
        patientID: [],
        isReserved: [],
    },
    getSignableDocumentsListRequest: {
    },
    getNextDocumentToSignRequest: {
    },
    getSignableDocumentRequest: {
        documentID: [],
    },
    saveSignableDocumentRequest: {
        documentID: [],
        images: [],
        userPassword: [],
    },
    pdfPageImage: {
        index: [],
        contents: [],
    },
    setSignableDocumentStatusRequest: {
        documentID: [],
        status: [],
    },
    getDoctorSignatureRequest: {
    },
    jRequestListRequest: {
        fromDate: [],
        toDate: [],
    },
    newJRequestRequest: {
        patientID: [],
    },
    deleteJRequestRequest: {
        jRequestID: [],
    },
    jRequestPriceListRequest: {
        patientID: [],
        cabinetID: [],
    },
    jOrdersInfoRequest: {
        jRequestID: [],
    },
    saveJRequestRequest: {
        item: [],
    },
    jRequestEditDto: {
        senderDoctorID: [ validations.required('Моля, изберете насочен от!') ],
        jRequestNote: [],
        cabinetID: [ validations.required('Моля, изберете сектор!') ],
        labMaterialID: [ validations.required('Моля, изберете материал!') ],
        materialNote: [],
        nrNErifFormatedNumber: [],
        services: [],
        vaucherCode: [],
    },
    priceListDepartmentCM: {
        serviceID: [],
        serviceNote: [],
        priceID: [],
        serviceCodeMkb9: [],
        labServicePackJSon: [],
        acCode: [],
        acName: [],
        serviceName: [],
        serviceCategoryID: [],
        serviceCategoryName: [],
        labGroupID: [],
        isFixedPrice: [],
        servicePrice: [],
        jDiscountPercent: [],
        jQuantity: [],
        jPartnerID: [],
        patientID: [],
        departmentPationID: [],
        spineID: [],
        isCheck: [],
        clinicProcID: [],
        ivfArtID: [],
        packJOrderID: [],
        vatGroupID: [],
        jPartnerEnum: [],
        jPartnerName: [],
        discountJAmount: [],
        defaultCabinetID: [],
        isKsmpPril21a: [],
    },
    jRequestReportRequest: {
        jRequestID: [],
    },
    generateQrCodeRequest: {
        data: [],
    },
    doctorAgentMeetingsCatalogRequest: {
        fromDate: [],
        toDate: [],
    },
    getMedicalAgentNewEditRequest: {
        agentMeetingId: [],
    },
    saveMedicalAgentRequest: {
        item: [],
    },
    doctorAgentMeetingDto: {
        agentMeetingID: [],
        agentDoctorid: [ validations.required('Липсва медицинския представител') ],
        doctorid: [ validations.required('Липсва лекар') ],
        meetingDatetime: [ validations.required('Попълнете дата и час на срещата') ],
        meetingPlace: [ validations.required('Попълнете място на срещата') ],
        meetingSubject: [],
        acodeStatusID: [],
    },
    deleteDoctorAgentMeetingRequest: {
        agentMeetingId: [],
    },
    microbiologyResultListRequest: {
        fromDate: [],
        toDate: [],
        patientID: [],
        patientIdentNumberBeginsWith: [],
    },
    getMicrobiologyResultRequest: {
        patientRecID: [],
    },
    newMicrobiologyResultRequest: {
    },
    saveMicrobiologyResultRequest: {
        item: [],
    },
    microbiologyResultDto: {
        conclusion: [],
        doctorID: [],
        examDateTime: [],
        material: [],
        microbes: [],
        microscopeExam: [],
        patientID: [],
        patientRecordID: [],
        senderDoctorID: [ validations.required('Моля, изберете насочил лекар.') ],
    },
    microbeDto: {
        antibiogrameACodeShortName: [],
        grade: [],
        antibiograme: [],
        microbeName: [],
        quantity: [],
    },
    antibiogrameDto: {
        aCode: [],
        aCodeShortName: [],
        aCodeName: [],
        sensitivity: [],
    },
    saveCombineMicrobiologyResultRequest: {
        item: [],
    },
    combineMicrobiologyResultDto: {
        conclusion: [],
        doctorID: [],
        examDateTime: [],
        material: [],
        microbes: [],
        microscopeExam: [],
        patientID: [],
        patientRecordID: [],
        senderDoctorID: [ validations.required('Моля, изберете насочил лекар.') ],
    },
    printMicrobiologyResultRequest: {
        patientRecID: [],
    },
    deleteMicrobiologyResultRequest: {
        patientRecID: [],
    },
    searchPatientsRequest: {
        patientID: [],
        patientIdentNumberBeginsWith: [],
        patientFirstName: [],
        patientSecondName: [],
        patientLastName: [],
    },
    patientInformationRequest: {
        patientID: [],
    },
    newPatientRequest: {
    },
    savePatientRequest: {
        item: [],
    },
    getPatientForEditRequest: {
        patientID: [],
        patientIdentNumber: [],
    },
    sendPatientLoginInfoRequest: {
        patientID: [],
    },
    listDoctorTemplatesRequest: {
        templateTypeID: [],
    },
    getDoctorTemplateRequest: {
        templateID: [],
    },
    deleteDoctorTemplateRequest: {
        templateID: [],
    },
    saveDoctorTemplatesRequest: {
        item: [],
    },
    doctorTemplateDto: {
        templateDescription: [],
        templateID: [],
        templateShortName: [],
        templateTypeID: [],
        templateTypeName: [],
    },
    newDoctorTemplateRequest: {
        templateTypeID: [],
    },
    saveSessionInfoRequest: {
        state: [],
    },
    webSessionStateModel: {
        getDoctorJRequestsRequest: [],
        cytologyResultListRequest: [],
        attachedResultsListRequest: [],
        microbiologyResultListRequest: [],
        senderDoctorResultListRequest: [],
    },
    getSessionInfoRequest: {
    },
    getDicomJStudiesRequest: {
        patientNames: [],
        patientUID: [],
        fromDate: [],
        toDate: [],
    },
    getDicomInstancesRequest: {
        studyInstanceUID: [],
    },
    setDoctorDicomJStudyArchiveForProcessingRequest: {
        studyInstanceUID: [],
        downloadEmail: [ validations.email('Моля, въведете правилен email адрес.') ],
    },
    setPatientDicomJStudyArchiveForProcessingRequest: {
        studyInstanceUID: [],
        downloadEmail: [ validations.email('Моля, въведете правилен email адрес.') ],
    },
    getDoctorDicomJStudyArchiveStatusRequest: {
        studyInstanceUID: [],
    },
    getPatientDicomJStudyArchiveStatusRequest: {
        studyInstanceUID: [],
    },
    downloadDicomJStudyArchiveRequest: {
        dicomDownloadID: [],
    },
    getPatientImagingRequest: {
    },
    getPromotionsRequest: {
        promotionGuid: [],
    },
    savePromotionDetailRequest: {
        item: [],
    },
    promotionDto: {
        promotionID: [],
        promotionDetailID: [],
        promotionCode: [],
        promotionStatus: [],
        sendToMobile: [],
        sendToEmail: [],
        sendToNote: [],
        shouldSend: [],
    },
    getPromotionDetailRequest: {
        promotionDetailID: [],
    },
    checkPromotionDetailRequest: {
        promotionDetailCode: [],
    },
    loginRequest: {
        password: [ validations.required('Моля, въведете парола.') ],
        username: [ validations.required('Моля, въведете потребителско име.') ],
        rememberMe: [],
    },
    loginWithTokenRequest: {
        tokenValue: [],
    },
    changeUsernameAndPasswordRequest: {
        currentPassword: [ validations.required('Моля, въведете парола.') ],
        newPassword: [ validations.required('Моля, въведете нова парола.'), validations.minLength(6, 'Паролата трябва да е поне 6 символа.'), validations.maxLength(50, 'Паролата не трябва превишава 50 символа.') ],
        newPasswordConfirm: [ validations.required('Моля, потвърдете паролата.') ],
        newUsername: [ validations.required('Моля, въведете ново потребителско име (email адрес).'), validations.email('Моля, въведете правилен email адрес.'), validations.maxLength(50, 'Потребителското име не трябва превишава 50 символа.') ],
    },
    invalidateAccountRequest: {
    },
    doctorChangePasswordRequest: {
        currentPassword: [ validations.required('Моля, въведете парола.') ],
        newPassword: [ validations.required('Моля, въведете нова парола.'), validations.minLength(6, 'Паролата трябва да е поне 6 символа.') ],
        newPasswordConfirm: [ validations.required('Моля, потвърдете паролата.') ],
    },
};
