import React from 'react';
import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {PdfDocument} from '../infrastructure/components/PdfDocument';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {Result} from '../infrastructure/api-result';
import {ErrorMessages} from '../infrastructure/errors';
import {PatientSignedDocumentDto} from '../dto';
import {JDateTime} from '../public/JDateTime';
import {base64ToArray, downloadFile} from '../infrastructure/util';

interface Props extends AppContextProps {
}

interface State {
  errorMessages: string[];
  loading: boolean;
  loadingDocument: boolean;
  documentBytes: Uint8Array | undefined;
  documentLoadResult: Result | undefined;
  documentContent: string | undefined;
  documentTitle: string | undefined;
  list: PatientSignedDocumentDto[] | undefined;
}

export class PatientSignedDocuments extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    loading: false,
    loadingDocument: false,
    documentBytes: undefined,
    documentContent: undefined,
    documentLoadResult: undefined,
    list: undefined,
    documentTitle: undefined,
  };

  async componentDidMountAsync(): Promise<void> {
    const {server} = this.props.context;
    await this.setStateAsync({loading: true});

    const response = await server.getPatientSignedDocumentsList({});
    if (!response.success) {
      await this.setStateAsync({
        errorMessages: response.errorMessages,
        loading: false,
      });

      return;
    }

    await this.setStateAsync({
      list: response.payload.signedDocuments,
      loading: false,
    });
  }

  async fetchSignedDocument(dto: PatientSignedDocumentDto) {
    const {server, actions} = this.props.context;
    await this.setStateAsync({
      loadingDocument: true,
      documentContent: undefined,
      documentBytes: undefined,
    });

    const response = await server.getPatientSignedDocument({signedDocument: dto});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);

      await this.setStateAsync({
        loadingDocument: false,
        documentContent: undefined,
        documentBytes: undefined,
      });
      return;
    }

    await this.setStateAsync({
      documentContent: response.payload.base64Content,
      documentBytes: base64ToArray(response.payload.base64Content as string),
      documentTitle: response.payload.documentTitle,
      documentLoadResult: undefined,
      loadingDocument: false,
    });

    window.scrollTo(0, 0);
  }

  downloadPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print while documentContent is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  render() {
    const {loading, errorMessages, documentBytes, documentLoadResult, loadingDocument, list} = this.state;

    return (
      <div className="patient-signing-documents my-5">

        <DocumentTitle title={'Подписани документ'}/>
        {loading && <LoadingIndicator/>}

        {!!errorMessages && errorMessages.length > 0 && <ErrorMessages errors={errorMessages}/>}
        <div className="row">
          <div className="col-xl-6">
            {loadingDocument && <LoadingIndicator/>}
            {!!documentLoadResult && !documentLoadResult.success &&
                <ErrorMessages errors={documentLoadResult.errorMessages}/>}
            {documentBytes && !loading &&
                <>
                    <button className="btn btn-md btn-success"
                            onClick={this.downloadPdf}>
                        Изтегли / Download
                    </button>
                    <PdfDocument data={documentBytes}/>
                </>
            }
          </div>
          <div className="col-xl-6">
            {!loading &&
                <>
                    <h4>Документи</h4>
                  {list && list.length > 0 ? (
                    list.map((x) => (
                      <div key={x.signDocID} className="card mb-2" style={{cursor: 'pointer'}}
                           onClick={() => this.fetchSignedDocument(x)}>
                        <div className="card-body p-3 d-flex justify-content-between border border-info">
                          <div>{x.docName}</div>
                          <div>{JDateTime.toJoystickDateTimeFormat(x.signedDatetime)}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-muted">Няма документи за визуализиране</div>
                  )}
                </>
            }
          </div>
        </div>
      </div>
    );
  }
}
